import HeaderFirst from "./HeaderFirst";
import Footer from "./Footer";
import { Row,Col, Modal, Button } from "antd"
import React,{ useState } from 'react';
import Thermometer from "../../icon/thermometer.svg"
import Barometer from "../../icon/barometer.svg"
import Windsock from "../../icon/windsock.svg"
import Humidity from "../../icon/humidity.svg"
import Raindrop from "../../icon/raindrop-measure.svg"
import { Link } from "react-router-dom";
import Tushaal from '../../ifile/727.pdf';
import Map from "./Map";

const Dashboard=()=>{
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [mapModalOpen, setMapModalOpen] = useState(false);
    const [modalInfo, setModalInfo] = useState(true);
    const [modalContent, setModalContent] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const showModal = (val, title) => {
      setIsModalOpen(true);
      setModalContent(val)
      setModalTitle(title)
    };
    const handleOk = () => {
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };
    const handleModal=()=>{
        setModalInfo(false);
    }

    const openMapModal=()=>{
        setMapModalOpen(true)
    }


    return(<div className="flex flex-col justify-between h-screen">
        <HeaderFirst/>
        <div className="dashboard container mx-auto my-2 relative">
            <Row gutter={[16,16]} >
                <Col onClick={()=>showModal('payment', 'Төлбөрийн мэдээлэл')} xs={24} sm={24} md={12} lg={8} xl={6}>
                    <div style={{  backgroundPosition:'center',backgroundRepeat:'no-repeat', backgroundSize:'contain' }} className="relative  first h-72 md:h-72 rounded-md cursor-pointer flex text-black bg-white border border-gray-300  drop-shadow-md">
                        <div className="sec flex flex-col absolute h-full top-0 left-0 p-4 border-none rounded">
                            <span className="font-bold text-xl w-full border-b border-slate-500 text-left pb-2 mb-2">Төлбөрийн мэдээлэл</span>
                            <p style={{textOverflow:'ellipsis',height: '90%',display: 'block',whiteSpace: 'normal',overflow: 'hidden'}} className="text-md text-justify">
                            Цаг уур, орчны шинжилгээний бүх төрлийн мэдээллийг ажиглалтын цэг бүрээр /өртөө, харуул/ хүссэн хугацаагаар дундаж, хамгийн их, хамгийн бага, нийлбэрийг гарган авах боломжтой.
                            </p>
                        </div>
                    </div>
                </Col>
                <Col onClick={()=>showModal('air_temp', 'Агаарын температур')} xs={24} sm={24} md={12} lg={8} xl={6}>
                    <div style={{ background:`url(${Thermometer})`, backgroundPosition:'center',backgroundRepeat:'no-repeat', backgroundSize:'contain' }} className="relative  first h-72 md:h-72 rounded-md cursor-pointer flex text-black bg-white border border-gray-300  drop-shadow-md">
                        <div className="sec flex flex-col absolute h-full top-0 left-0 p-4 border-none rounded">
                            <span className="font-bold text-xl w-full border-b border-slate-500 text-left pb-2 mb-2">Агаарын температур</span>
                            <p style={{textOverflow:'ellipsis',height: '90%',display: 'block',whiteSpace: 'normal',overflow: 'hidden'}} className="text-md text-justify">
                                Орчин ба биетийн дотоод энергийн түвшин, дулааны төлөвийг харуулах физик хэмжигдэхүүнийг температур гэдэг. Температур нь молекулын эмх замбараагүй хэлбэлзэх хөдөлгөөний үр дагавар бөгөөд массаас хамаарахгүй, бие даасан шинж чанарын үзүүлэлт мөн. Температур нь молекулын кинетик энерги гэдгээс түүний хэмжээсийг энергийн нэгж (СИ системд жоуль)-ээр илэрхийлбэл зохистой атал молекулын кинетик онол үүсэхээс олон жилийн өмнөөс температурыг хэмжиж, зохиомол нэгж “градус”-ыг буй болгосон байдаг.
                            </p>
                        </div>
                    </div>
                </Col>
               <Col  onClick={()=>showModal('rain', 'Хур тунадас')} xs={24} sm={24} md={12} lg={8} xl={6}>
                    <div style={{ background:`url(${Raindrop})`, backgroundPosition:'center',backgroundRepeat:'no-repeat', backgroundSize:'contain' }} className="relative  first h-72 md:h-72 rounded-md cursor-pointer flex text-black bg-white border border-gray-300  drop-shadow-md">
                        <div className="sec flex flex-col absolute h-full top-0 left-0 p-4 border-none rounded">
                            <span className="font-bold text-xl w-full border-b border-slate-500 text-left pb-2 mb-2">Хур тунадас</span>
                            <p style={{textOverflow:'ellipsis',height: '90%',display: 'block',whiteSpace: 'normal',overflow: 'hidden'}} className="text-md text-justify">
                                Хур тунадасны хэмжилт нь үзэгдлийг таньж, ялгаж, эрч хүчээр нь зааглаж 
                                тэмдэглэх, орж буй хур тунадасны эхэлсэн, дууссан, үргэлжилсэн хугацаа, 
                                нийлбэр хэмжээг тодорхойлох ажлаас бүрдэнэ. Хур тунадасны хэлбэр, орж 
                                буй хугацаа, эрч хүчийг үнэлэх ажиглалтыг “агаарын үзэгдэл” гэсэн бүлэгт 
                                заасны дагуу хийнэ.
                                Хур тунадасны хэмжээ гэдэг нь бороо, цас, шиврээ, их хэмжээний 
                                шүүдэр, хяруу, хялмаа, манан, мөндөр, аадар, туйлаадас зэргээс газрын 
                                гадарга дээр урсалгүй, ууршилгүй, хөрсөнд нэвчилгүй тогтож болох усан 
                                давхаргын зузааныг миллиметрээр илэрхийлснийг хэлнэ. Хур тунадасны 
                                хэмжээг Третьяковын тунадас хэмжүүрээр тодорхойлно.
                            </p>
                        </div>
                    </div>
                </Col>
                <Col onClick={()=>showModal('wind', 'Салхины хурд')} xs={24} sm={24} md={12} lg={8} xl={6}>
                    <div style={{ background:`url(${Windsock})`, backgroundPosition:'center',backgroundRepeat:'no-repeat', backgroundSize:'contain' }} className="relative  first h-72 md:h-72 rounded-md cursor-pointer flex text-black bg-white border border-gray-300  drop-shadow-md">
                        <div className="sec flex flex-col absolute h-full top-0 left-0 p-4 border-none rounded">
                            <span className="font-bold text-xl w-full border-b border-slate-500 text-left pb-2 mb-2">Салхины хурд</span>
                            <p style={{textOverflow:'ellipsis',height: '90%',display: 'block',whiteSpace: 'normal',overflow: 'hidden'}} className="text-md text-justify">Газрын гадаргын дaгyy хэвтээ чиглэлээр хөдөлж байгаа агаарын урсгалыг салхи гэнэ. Агаарын энэхүү хэвтээ чиглэлийн урсгалын чиглэл ба хурдыг флюгер, анемометр болон бусад хэмжих хэрэгслээр хэмжих боломжтой. Харин өндрийн салхины чиглэл хурдыг бөмбөлөг, радиозонд хөөргөх гэх мэтийн аргаар тодорхойлно.</p>
                        </div>
                    </div>
                </Col>
                <Col onClick={()=>showModal('pressure', 'Агаарын даралт')} xs={24} sm={24} md={12} lg={8} xl={6}>
                    <div style={{ background:`url(${Barometer})`, backgroundPosition:'center',backgroundRepeat:'no-repeat', backgroundSize:'contain' }} className="relative  first h-72 md:h-72 rounded-md cursor-pointer flex text-black bg-white border border-gray-300  drop-shadow-md">
                        <div className="sec flex flex-col absolute h-full top-0 left-0 p-4 border-none rounded">
                            <span className="font-bold text-xl w-full border-b border-slate-500 text-left pb-2 mb-2">Агаарын даралт</span>
                            <p style={{textOverflow:'ellipsis',height: '90%',display: 'block',whiteSpace: 'normal',overflow: 'hidden'}} className="text-md text-justify">Даралт гэдэг нь материйн оршин буй орон зайндаа жижигрэхгүй байхыг эсэргүүцэх эсэргүүцлийн хэмжээс юм. Даралт нь скаляр физикийн хэмжигдхүүн бөгөөд СИ системийн нэгж паскалиар хэмжинэ. Томьёонд тэмдэглэхдээ P үсгээр тэмдэглэх бөгөөд энэ нь латин болон англи хэлний даралт (лат. pressio англ. pressure) гэсэн үгний эхний үсэг юм.</p>
                        </div>
                    </div>
                </Col>

                <Col onClick={()=>showModal('humidity', 'Агаарын чийг')} xs={24} sm={24} md={12} lg={8} xl={6}>
                    <div style={{ background:`url(${Humidity})`, backgroundPosition:'center',backgroundRepeat:'no-repeat', backgroundSize:'contain' }} className="relative  first h-72 md:h-72 rounded-md cursor-pointer flex text-black bg-white border border-gray-300  drop-shadow-md">
                        <div className="sec flex flex-col absolute h-full top-0 left-0 p-4 border-none rounded">
                            <span className="font-bold text-xl w-full border-b border-slate-500 text-left pb-2 mb-2">Агаарын чийг</span>
                            <p style={{textOverflow:'ellipsis',height: '90%',display: 'block',whiteSpace: 'normal',overflow: 'hidden'}} className="text-md text-justify">
                            Агаарын нэгж эзлэхүүн /масс/-д агуулагдаж байгаа усны уурын 
                        тоо хэмжээг агаарын чийгшил гэнэ. Цаг уурт агаарын чийгшлийн дараах 
                        үзүүлэлтүүдийг тодорхойлно.
                        а/. Үнэмлэхүй чийгшил /а/. Нэгж эзлэхүүн агаарт агуулагдах усны уурын 
                        массыг граммаар илэрхийлсэнийг үнэмлэхүй чийгшил гэнэ [а=г/м3
                        , а=г/см3
                        ]. 
                            </p>
                        </div>
                    </div>
                </Col>

                <Col onClick={()=>showModal('synop', 'Синоптик цаг уурын ажиглалт')} xs={24} sm={24} md={12} lg={8} xl={6}>
                    <div style={{  backgroundPosition:'center',backgroundRepeat:'no-repeat', backgroundSize:'contain' }} className="relative  first h-72 md:h-72 rounded-md cursor-pointer flex text-black bg-white border border-gray-300  drop-shadow-md">
                        <div className="sec flex flex-col absolute h-full top-0 left-0 p-4 border-none rounded">
                            <span className="font-bold text-xl w-full border-b border-slate-500 text-left pb-2 mb-2">Синоптик цаг уурын ажиглалт</span>
                            <p style={{textOverflow:'ellipsis',height: '90%',display: 'block',whiteSpace: 'normal',overflow: 'hidden'}} className="text-md text-justify h-auto w-auto">           
                                Синоптик цаг уурын ажиглалт гэдэг нь цаг агаарын байдлыг синоптик масштабаар ойлгохын тулд 
                                бүх ажиглалтын станцаас тогтсон цагт нэгэн зэрэг хийсэн газрын ажиглалтыг хэлнэ. Синоптик хуваарь нь цаг агаарын графикт 
                                илэрхийлсэн ердийн өндөр эсвэл бага даралтын орон зайн хэмжээ, ашиглалтын хугацааг хэлдэг бөгөөд голчлон өдөр тутмын цаг агаарын үзэгдлийг илэрхийлдэг.
                            </p>
                        </div>
                    </div>
                </Col>
                <Col onClick={()=>openMapModal()} xs={24} sm={24} md={12} lg={8} xl={6}>
                    <div style={{  backgroundPosition:'center',backgroundRepeat:'no-repeat', backgroundSize:'contain' }} className="relative  first h-72 md:h-72 rounded-md cursor-pointer flex text-black bg-white border border-gray-300  drop-shadow-md">
                        <div className="sec flex flex-col absolute h-full top-0 left-0 p-4 border-none rounded">
                            <span className="font-bold text-xl w-full border-b border-slate-500 text-left pb-2 mb-2">Станцуудын ерөнхий мэдээлэл</span>
                            <p style={{textOverflow:'ellipsis',height: '90%',display: 'block',whiteSpace: 'normal',overflow: 'hidden'}} className="text-md text-justify h-auto w-auto">           
                                Хоногийн 8 хугацааны ажиглалт хийдэг 130 гаруй станцын танилцуулга мэдээллийг доорх газрын зургаас авна уу.
                            </p>
                        </div>
                    </div>
                </Col>
                <Col className="rounded-2xl" span={24}>
                    <Map visible={mapModalOpen} onChange={(e)=>setMapModalOpen(e)}/>
                </Col>
            </Row>
            <Modal 
            cancelButtonProps={{ style: { display: 'none' } }} 
            width={1200} title={modalTitle} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
            footer={[
                <Link to={'/request'}>
                    <Button key="back" type="primary" className="bg-white border border-blue-500 text-blue-500 mr-2" onClick={handleCancel}>
                        Мэдээ авах
                    </Button>
                </Link>
                ,
                <Button key="submit" type="primary"  onClick={handleCancel}>
                  Хаах
                </Button>,
            ]}
            >
                {
                    modalContent ==='wind' ?
                    <div className="border-t border-black text-md">
                        <p className="font-bold mt-4">Салхи гэж юу вэ?</p>
                        <p>Газрын гадаргын дaгyy хэвтээ чиглэлээр хөдөлж байгаа агаарын урсгалыг салхи гэнэ. Агаарын энэхүү хэвтээ чиглэлийн урсгалын чиглэл ба хурдыг флюгер, анемометр болон бусад хэмжих хэрэгслээр хэмжих боломжтой. Харин өндрийн салхины чиглэл хурдыг бөмбөлөг, радиозонд хөөргөх гэх мэтийн аргаар тодорхойлно</p>
                        <p className="font-bold mt-4">Салхины хурд, чиглэлийг тодорхойлох</p>
                        <p>Салхины хурд, чиглэл богино хугацаанд өөрчлөгдөж байдаг учраас цаг 
                        уурт флюгерээр салхины ажиглалт хийхэд 4 минутын хугацаа зарцуулна. 
                        Эхний 2 минутанд чиглэлийг, дараагийн 2 минутанд хурдыг тодорхойлдог. 
                        Хаанаас /аль зүгээс/ салхилж байгаагаар нь салхины чиглэлийг тодорхойлно. 
                        Нэгж хугацаанд агаарын урсаж өнгөрсөн замыг салхины хурд гэх бөгөөд 
                        салхины хурдыг секундэд хэдэн метр явснаар нь (м/с-ээр) илэрхийлнэ. 
                        Салхины чиглэлийг 8 зүг, 8 зовхисоор тодорхойлж тэмдэглэнэ.</p>
                        <p className="font-bold mt-4">Салхины ажиглалтыг хийх дараалал</p>
                        <p>1. 2 минyтын туршид салхины дундаж чиглэлийг тодорхойлох
                        2. 2 минyтын туршид салхины дундаж хурдыг тодорхойлох 
                        3. салхины хурд жигд бус үед өрөвхийлөлтийг тодорхойлох
                        4. ажиглалтын хугацааны хоорондох салхины хамгийн их хурдыг ажиглаж 
                        байх. Өртөө, харуулд салхины хурд, чиглэлийг хэмжихэд хүнд ба хөнгөн самбартай 
                        флюгер, цаг уурын автомат станц бусад хэмжих хэрэгслийг ашиглана.</p>
                    </div>
                    :
                    modalContent ==='air_temp' ?
                    <div className="border-t border-black text-md">
                        <p className="font-bold mt-4">Температур</p>
                        <p>Орчин ба биетийн дотоод энергийн түвшин, дулааны төлөвийг харуулах 
                        физик хэмжигдэхүүнийг температур гэдэг. Температур нь молекулын эмх 
                        замбараагүй хэлбэлзэх хөдөлгөөний үр дагавар бөгөөд массаас хамаарахгүй, 
                        бие даасан шинж чанарын үзүүлэлт мөн. Температур нь молекулын кинетик 
                        энерги гэдгээс түүний хэмжээсийг энергийн нэгж (СИ системд жоуль)-ээр 
                        илэрхийлбэл зохистой атал молекулын кинетик онол үүсэхээс олон жилийн 
                        өмнөөс температурыг хэмжиж, зохиомол нэгж “градус”-ыг буй болгосон 
                        байдаг.</p>
                        <p className="font-bold mt-4">Температурыг хэмжих</p>
                        <p>
                        Температурыг хэмжихийн тулд тооллын эх, хэмжлийн нэгж, хуваарийн 
                        алхамыг тогтооно. Температурын хуваарийг зохиохын тулд энергийн 
                        тодорхой түвшинд байх төлөв байдал “усны фазын шилжилтийн төлөв”-ийг 
                        сонгосон байдаг. Доод хязгаар болгож хатуу-шингэн төлөвийн зааг, дээд 
                        хязгаар болгож шингэн-хийн төлөвийн заагийг авч зайцад хуваан хэмжих 
                        нэгж, алхамыг тогтоожээ. 
                        Г.Галилей 1598 онд термометрийг зохион бүтээснээс эхлэн 1715 онд 
                        Фарангейт, 1736 онд Реомюр, 1748 онд Цельс, мөн онд Кельвин өөрсдийн 
                        температурыг хэмжих хуваарийг зохион ашиглаж иржээ.</p>
                        <p className="font-bold mt-4">Термометрийн хуваарь</p>
                        <p>• Цельсийн хуваарь. Далайн түвшинд усны шингэн ба хатуу төлөвийн 
                        шилжилтийн температурыг 0C, буцлах температурыг 100C градус гэж 
                        авсан, 
                        • Фарангейт мөн усны хөлдөх гэсэхийн заагийг 32F, буцлахыг 212F 
                        градус гэж авсан, (зайг 212-32=180 хуваасан) 
                        • харин Кельвин тооцооны аргаар цельсийн –273.15 градус буюу 
                        бодисын дотоод энерги тэглэх температурыг 0K градус, 
                        • Реомюр мөсний хайлахыг 0R, усны буцлахыг 80R гэж авсан байна. 
                        (зайг 80 хуваасан)</p>
                    </div>
                    :modalContent ==='humidity' ?
                    <div className="border-t border-black text-md">
                        <p className="font-bold mt-4">Агаарын чийгшил</p>
                        <p>
                        Агаарын нэгж эзлэхүүн /масс/-д агуулагдаж байгаа усны уурын 
                        тоо хэмжээг агаарын чийгшил гэнэ. Цаг уурт агаарын чийгшлийн дараах 
                        үзүүлэлтүүдийг тодорхойлно.
                        а/. Үнэмлэхүй чийгшил /а/. Нэгж эзлэхүүн агаарт агуулагдах усны уурын 
                        массыг граммаар илэрхийлсэнийг үнэмлэхүй чийгшил гэнэ [а=г/м3
                        , а=г/см3
                        ]. 
                        Үнэмлэхүй чийг уурын даралт ба температуртай дараах хамааралтай байдаг.
                        б/. Усны уурын дарапт /е/, Агаарт орших усны уурын / хийн парциал/ даралт. 
                        Агаарын найрлага дахь нэг хийн даралт тул [гПа, мб, мм м. у. б.]-аар 
                        илэрхийлнэ.
                        в/. Ханасан уурын даралт /Е/. Тухайн температур /Ta=Td/-ын хувьд байж 
                        болох усны уурын даралтын дээд хязгаарын утгыг ханасан уурын даралт 
                        /Е/ гэж нэрлэдэг. Агаар дахь усны ханасан уурын даралт /Е/-ыг гектопаскал /
                        гПа/-аар илэрхийлдэг. 
                        34
                        г/. Харьцангуй чийг /f/. Агаар дахь усны уурын даралт /е/-ыг тухайн 
                        температурт байж болох усны уурын хамгийн их даралт /Е/-д харьцуулж, 
                        хувиар илэрхийлснийг харьцангуй чийгшил /f/ гэнэ. Нэгжийн хувь /0-1/ эсвэл
                        бүхэл хувь /%/-аар илэрхийлдэг. = ×100; E
                        e f
                        д/. Хувийн чийг /q/. Нэгж масстай агаарт агуулагдаж байгаа усны уурын 
                        массыг хувийн чийг гэнэ. Хувийн чийгийг “г/кг”-аар илэрхийлнэ. Хувийн чийг 
                        нь уурын даралт ба ханасан уурын даралттай дараах хамааралтай байдаг. </p>
                        <p className="font-bold mt-4">Агаарын чийг хэмжих нөхцөл</p>
                        <p>Монголын цаг уурын албанд 
                        газрын гадаргаас дээш 2.0 метрийн өндөрт, сүүдэрлэгдсэн орчинд, агаарын 
                        урсгал 0,8 м/с-ээс хэтрэхгүй, орчинтойгоо агаарын солилцоонд бүрэн орж 
                        байх бүхээгт агаарын температур, чийгийг хэмждэг.</p>
                    </div>
                    :modalContent ==='pressure' ?
                    <div className="border-t border-black text-md">
                        <p className="font-bold mt-4">Агаарын даралт</p>
                        <p>Италийн эрдэмтэн Э.Торричелли 1643 онд агаар жинтэй болохыг баталсан 
                        байна. Тэрээр 90 см урт шилэн гуурсны нэг үзүүрийг нь битүүлж мөнгөн 
                        усаар дүүргэн, нүхтэй үзүүрийг нь хуруугаараа таглаж мөнгөн устай аяганд 
                        дүрсний дараагаар хуруугаа авчээ. Тэгэхэд шилэн гуурсан дахь мөнгөн усны 
                        баганы өндөр 76 см болжээ. Энэ нь аяган дахь мөнгөн усны гадарга дээр 
                        дарж буй гаднах агаарын даралттай шилэн гуурс дахь мөнгөн ус тэнцвэрийн 
                        байдалд орсныг харуулж байна. Ийм аргаар агаарын даралтыг хэмжиж болох 
                        үндэслэл анх тавигджээ. Агаарын даралтыг хэмжих хэрэгслийг барометр 
                        гэнэ. Цаг уурын өртөөнд агаарын даралтыг мөнгөн уст аягат барометрээр 
                        хэмжинэ.</p>
                        <p className="font-bold mt-4">Агаарын даралтыг хэмжих нэгж</p>
                        <p>Агаарын даралтыг хэмжих нэгжид миллиметр 
                        /мм.м.у.б/, миллибар /мб/-г хэрэглэж ирсэн. Одоо олон улсын “СИ” системээр 
                        агаарын даралтын нэгжид гиктопаскаль /гПа/-ийг хэрэглэж байна. 3арим 
                        тохиолдолд агаарын даралтыг миллиметрээр /мм/ илэрхийлдэг учраас 
                        түүнийг миллибарт эсвэл миллибараас миллиметрт шилжүүлэх шаардлага 
                        гарвал доорх харьцааг ашиглана.</p>
                    </div>
                    :modalContent ==='rain' ?
                    <div className="border-t border-black text-md">
                        <p className="font-bold mt-4">Хур тунадас</p>
                        <p>Хур тунадасны хэмжилт нь үзэгдлийг таньж, ялгаж, эрч хүчээр нь зааглаж 
                        тэмдэглэх, орж буй хур тунадасны эхэлсэн, дууссан, үргэлжилсэн хугацаа, 
                        нийлбэр хэмжээг тодорхойлох ажлаас бүрдэнэ. Хур тунадасны хэлбэр, орж 
                        буй хугацаа, эрч хүчийг үнэлэх ажиглалтыг “агаарын үзэгдэл” гэсэн бүлэгт 
                        заасны дагуу хийнэ.
                        Хур тунадасны хэмжээ гэдэг нь бороо, цас, шиврээ, их хэмжээний 
                        шүүдэр, хяруу, хялмаа, манан, мөндөр, аадар, туйлаадас зэргээс газрын 
                        гадарга дээр урсалгүй, ууршилгүй, хөрсөнд нэвчилгүй тогтож болох усан 
                        давхаргын зузааныг миллиметрээр илэрхийлснийг хэлнэ. Хур тунадасны 
                        хэмжээг Третьяковын тунадас хэмжүүрээр тодорхойлно.</p>
                    </div>
                    :modalContent ==='payment' ?
                    <div className="border-t border-black text-md">
                        {/* <p className="font-bold mt-4">Үйлчилгээний нөхцөл</p>
                        <p>Мэдээлэл хүссэн тухайн байгууллагын албан бичиг, хувь хүний өргөдлийг үндэслэн 1-2 хоногийн дотор мэдээллийг бүх төрлийн хэлбэртэйгээр гаргаж үйлчилнэ.</p>
                        <p className="font-bold mt-4">Үйлчилгээний төрөл</p> */}
                        <p>Цаг уур, орчны шинжилгээний бүх төрлийн мэдээллийг ажиглалтын цэг бүрээр /өртөө, харуул/ хүссэн хугацаагаар дундаж, хамгийн их, хамгийн бага, нийлбэрийг гарган авах боломжтой.</p>
                        <p className="font-bold mt-4 text-center">БАЙГАЛЬ ОРЧИН, УС ЦАГ УУРЫН МЭДЭЭЛЛИЙН БАРИМТЫН АРХИВААС ҮЗҮҮЛЭХ ҮЙЛЧИЛГЭЭНИЙ ХӨЛСНИЙ ХЭМЖЭЭ</p>
                        <table className="w-full text-center mt-4" style={{border:'2px solid',borderCollapse:'collapse'}}>
                            <thead>
                                <th className="border border-black">д/д</th>
                                <th className="border border-black">Үйлчилгээний төрөл</th>
                                <th className="border border-black">Хэмжих нэгж</th>
                                <th className="border border-black">Үйлчилгээний хөлс /төг/</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border border-black">1</td>
                                    <td className="border border-black">Хоногийн дүн, дундаж</td>
                                    <td className="border border-black">Нэг салбар, Нэг элемент</td>
                                    <td className="border border-black">200</td>
                                </tr>
                                <tr>
                                    <td className="border border-black">2</td>
                                    <td className="border border-black">Арав хоног, сарын дүн, дундаж</td>
                                    <td className="border border-black">Нэг салбар, Нэг элемент</td>
                                    <td className="border border-black">500</td>
                                </tr>
                                <tr>
                                    <td className="border border-black">3</td>
                                    <td className="border border-black">Жилийн дүн, дундаж</td>
                                    <td className="border border-black">Нэг салбар, Нэг элемент</td>
                                    <td className="border border-black">2,000</td>
                                </tr>
                                <tr>
                                    <td className="border border-black">4</td>
                                    <td className="border border-black">Цаг агаарын тодорхойлолт, лавлагаа</td>
                                    <td className="border border-black">Нэг тодорхойлолт</td>
                                    <td className="border border-black">20,000</td>
                                </tr>
                            </tbody>
                        </table>

                        Төлбөр тооцоолох тухай Архивын ерөнхий газрын даргын 2023 оны 08 дугаар сарын 24-ний өдрийн А/68 тоот тушаалын 3 дугаар хавсралт, <a type="application/pdf" target="_blank" className="text-blue-500 font-bold mt-4" href={Tushaal}>тушаалын хуулбар</a>/
                    </div>
                        :modalContent ==='synop' ?
                        <div className="border-t border-black text-md">
                            <p className="font-bold mt-4">Тайлбар</p>
                            <p>Синоптик цаг уурын ажиглалт гэдэг нь цаг агаарын байдлыг синоптик масштабаар ойлгохын тулд 
                                бүх ажиглалтын станцаас тогтсон цагт нэгэн зэрэг хийсэн газрын ажиглалтыг хэлнэ. Синоптик хуваарь нь цаг агаарын графикт 
                                илэрхийлсэн ердийн өндөр эсвэл бага даралтын орон зайн хэмжээ, ашиглалтын хугацааг хэлдэг бөгөөд голчлон өдөр тутмын цаг агаарын үзэгдлийг илэрхийлдэг.</p>
                            <p className="font-bold mt-4">Мэдээний хугацааны төрөл</p>
                            <p>Өдөр, Сар, Жил бүрийн дундаж</p>
                            <p className="font-bold mt-4">Мэдээний эхлэх хугацаа</p>
                            <p>1985 оноос (станц болон элементээр ялгаатай)</p>
                            <p className="font-bold mt-4">Станцын тоо</p>
                            <p>137 станц</p>
                            <p className="font-bold mt-4">Элементүүд</p>
                            <p>Температур, хур тунадас, салхи, агаарын даралт, чийг</p>
                          
                        </div>
             
                         : null
                        }

            </Modal>

            <Modal 
            cancelButtonProps={{ style: { display: 'none' } }} 
            title={"Цаг уур, орчны шинжилгээний газрын архив мэдээллийн санд тавтай морилно уу."} 
            width={600} open={modalInfo}  onOk={handleModal} onCancel={handleModal}
            footer={[
                <Link to={'/request'}>
                    <Button key="back" type="primary" className="bg-white border border-blue-500 text-blue-500 mr-2" onClick={handleModal}>
                        Мэдээ авах
                    </Button>
                </Link>
                ,
                <Button key="submit" type="primary"  onClick={handleModal}>
                  Хаах
                </Button>,
            ]}
              >
                 <p className="text-justify">
                    Энэхүү мэдээллийн сан нь цаг уурын 130 гаруй станцын, 1985 оноос хойших агаар болон хөрсний температур (дундаж, хамгийн их, хамгийн бага), хур тунадасны нийлбэр, агаарын даралт (дундаж), 
                    харьцангуй чийг(дундаж, хамгийн бага), салхины хурдны (дундаж, хамгийн их) хоног, сар, жилийн дундаж утгуудын мэдээг агуулсан болно. 
                    Хэрвээ та цаг уурын ажиглалтын мэдээг авахыг хүсвэл  {<Link  className="font-bold text-blue-500" to="/login">Нэвтрэх</Link>} холбоосоор өөрийн бүртгэлээ үүсгэж, нэвтрэн мэдээлэл авах боломжтой.
                    {/* Энэхүү мэдээллийн сан нь цаг уурын 130 гаруй станцын,  1985 оноос хойших хоног, сар, жилийн дунджийн 
                    агаар болон хөрсний температур, агаарын даралт, харьцангуй чийг, салхины хурд (дундаж, хамгийн их, хамгийн бага), хур тунадасны нийлбэр зэрэг ажиглалтын мэдээг агуулсан болно.
                    Хэрвээ та цаг уурын мэдээг авахыг хүсвэл {<Link  className="font-bold text-blue-500" to="/login">Нэвтрэх</Link>} холбоосыг дарж, өөрийн бүртгэлээ үүсгэж нэвтрэн орж мэдээлэл авах боломжтой. */}
                    <div className="w-full mt-4 text-red-600 font-semibold">Анхаар: Хур тунадасны хувьд 16448 гэсэн утга нь хур тунадас ороогүй гэдгийг илэрхийлнэ. Бусад элементүүдийн хувьд мэдээ тасарсныг илэрхийлнэ.</div>
                    <div className="w-full mt-4">Хэрвээ та Монгол улсын төлбөрийн системээр төлбөр төлөх боломжгүй бол archivemeteo@gmail.com хаягаар хандаж мэдээлэл авна уу.</div>
                 </p>

            </Modal>

            {/* <div  style={{position:'absolute',top: '50%',left: '50%',marginLeft:'-50%', top:0, height:'100vh',width:'100%'}} >
                <div className="w-full p-8">
                        <Map/>
                </div>
            </div> */}


        </div>
   
        <Footer/>
    </div>
    )

}

export default Dashboard;