import Logo from "../../icon/logo.png"
import { BsChatSquareTextFill } from 'react-icons/bs';
import { AiOutlineWindows, AiOutlineUnorderedList } from 'react-icons/ai';
import { IoIosCloseCircle } from 'react-icons/io';
import { useEffect, useState } from "react";
import {
    Link
} from 'react-router-dom';

const Side=(props)=>{
    const [menu, setMenu]= useState(false)

    return( 
        <>
        <div className="bg-white w-full h-screen px-4 py-4 text-sm text-gray-700 border-r border-slate-300 hidden xl:flex">
            <ul className="w-full">
                <li className="mb-10 w-auto">
                    <Link to="/"><img  className="w-32" src={Logo}/></Link>
                </li>
                <li><Link className={`w-full cursor-pointer hover:bg-blue-400 ${props.selected==="request"  ? 'bg-blue-500 text-white' : 'bg-white' }  hover:text-white rounded-md transition duration-400 py-2 px-2 flex items-center mb-2`} to="/request"><BsChatSquareTextFill/><span className="ml-4">Шинээр мэдээ авах</span></Link></li>
                <li><Link className={`w-full cursor-pointer hover:bg-blue-400 ${props.selected==="services"  ? 'bg-blue-500 text-white' : 'bg-white' } hover:text-white rounded-md transition duration-400 py-2 px-2 flex items-center `} to="/services"><AiOutlineWindows/><span className="ml-4">Миний авсан мэдээ</span></Link></li>
            </ul>
       </div>
       <div className="text-left m-4 xl:pl-0  text-2xl xl:hidden">
         <AiOutlineUnorderedList size={24} className="cursor-pointer hover:text-sky-500" onClick={()=>setMenu(true)} />
       </div>
       <div className={`absolute z-40 top-0 h-screen w-full absolute bg-opacity-30 bg-gray-300 transition duration-700 ${ menu? 'translate-x-0 ease-out': ' -translate-x-full' } `}>
        <div className="bg-white sm:w-64 w-full px-4 py-4 text-sm text-gray-700 h-screen border-r border-slate-300 static">
                <ul>
                    <li className="mb-10 w-full">
                        <Link to="/request"><img  className="w-32" src={Logo}/></Link>
                    </li>
                    <li><Link className={`w-full cursor-pointer hover:bg-blue-400 ${props.selected==="request"  ? 'bg-blue-500 text-white' : 'bg-white' }  hover:text-white rounded-md transition duration-400 py-2 px-2 flex items-center mb-2`} to="/request"><BsChatSquareTextFill/><span className="ml-4">Шинээр мэдээ авах</span></Link></li>
                    <li><Link className={`w-full cursor-pointer hover:bg-blue-400 ${props.selected==="services"  ? 'bg-blue-500 text-white' : 'bg-white' } hover:text-white rounded-md transition duration-400 py-2 px-2 flex items-center `} to="/services"><AiOutlineWindows/><span className="ml-4">Миний авсан мэдээ</span></Link></li>
                </ul>
                <button onClick={()=>setMenu(false)} className="absolute top-0 right-0 p-4 cursor-pointer text-2xl text-blue-500"><IoIosCloseCircle/></button>
        </div>
       </div>
       </>
    )
}

export default Side;