import * as api from '../api';
import * as constants from "../actionTypes";

export const addUser = (props)=> async (dispatch)=> {
    try {
        dispatch({
            type: constants.addUser.REQUEST
        })
        const { data } = await api.addUser(props);
        dispatch ({
            type: constants.addUser.RESPONSE,
            payload:data 
        });
        return data
    } catch (error) {
        console.log(error.message)
    }
}
export const login = (props)=> async (dispatch)=> {
    try {
        dispatch({
            type: constants.login.REQUEST
        })
        const { data } = await api.login(props);
        dispatch ({
            type: constants.login.RESPONSE,
            payload:data 
        });
        return data
    } catch (error) {
        return(error.message)
    }
}

export const forgotPassword = (props)=> async (dispatch)=> {
    try {
        dispatch({
            type: constants.forgotPassword.REQUEST
        })
        const { data } = await api.forgotPassword(props);
        dispatch ({
            type: constants.forgotPassword.RESPONSE,
            payload:data 
        });
        return data
    } catch (error) {
        console.log(error.message)
    }
}

export const getLogin= (props)=> async (dispatch)=> {
    try {
        dispatch({
            type: constants.getLogin.REQUEST
        })
        const { data } = await api.getLogin();
        dispatch ({
            type: constants.getLogin.RESPONSE,
            payload:data 
        });
        return data
    } catch (error) {
        console.log(error.message)
    }
}
export const LogOut = (props)=> async (dispatch)=> {
    try {
        dispatch({
            type: constants.LogOut.REQUEST
        })
        const { data } = await api.getLogOut();
        dispatch ({
            type: constants.LogOut.RESPONSE,
            payload:data 
        });
        return data
    } catch (error) {
        console.log(error.message)
    }
}
export const getUsers = (props)=> async (dispatch)=> {
    try {
        dispatch({
            type: constants.getUsers.REQUEST
        })
        const { data } = await api.getUsers(props);
        dispatch ({
            type: constants.getUsers.RESPONSE,
            payload:data 
        });
        return data
    } catch (error) {
        return (error.message)
    }
}


export const updateUser = (props)=> async (dispatch)=> {
    try {
        dispatch({
            type: constants.updateUser.REQUEST
        })
        const { data } = await api.updateUser(props);
        dispatch ({
            type: constants.updateUser.RESPONSE,
            payload:data 
        });
        return data
    } catch (error) {
        console.log(error.message)
    }
}



// export const login = (props)=> async (dispatch)=> {
//     try {
//         dispatch({
//             type: constants.login.REQUEST
//         })
//         const { data } = await api.login(props);
//         dispatch ({
//             type: constants.login.RESPONSE,
//             payload:data 
//         });
//         return data
//     } catch (error) {
//         return(error.message)
//     }
// }






