import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { Card, Statistic, Row, Col, DatePicker, Button, Steps, Modal, Space, Table, Tag, InputNumber, Input, Tooltip, Popover, Spin, message  } from "antd"
// import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons"
import ReactEcharts from "echarts-for-react"; 
import dayjs from 'dayjs';
import { getDashboardSingle, getDashboardMulti } from '../redux/actions/obsAction';
const { RangePicker } = DatePicker;

const reducer = ({ user, obs }) => ({  user, obs });

const Main=(props)=>{
    const obs = props.obs
    const colors = ['#5470C6', '#EE6666'];
    const option = {
        color: colors,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        grid: {
          right: '20%'
        },
        toolbox: {
          feature: {
            dataView: { show: true, readOnly: false },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        legend: {
          data: ['Нийт орлого', 'Нийт хүсэлт']
        },
        xAxis: [
          {
            type: 'category',
            axisTick: {
              alignWithLabel: true
            },
            // prettier-ignore
            // data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
            data: (obs?.dashboard_multi || []).map(el=>{
               return el.Date
            })
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: 'Нийт орлого',
            position: 'right',
            alignTicks: true,
            axisLine: {
              show: true,
              lineStyle: {
                color: colors[0]
              }
            },
            axisLabel: {
              formatter: '{value} ₮'
            }
          },
          {
            type: 'value',
            name: 'Нийт хүсэлт',
            position: 'left',
            alignTicks: true,
            axisLine: {
              show: true,
              lineStyle: {
                color: colors[1]
              }
            },
            axisLabel: {
              formatter: '{value}',
            },
            interval: 1,
          }
        ],
        series: [
          {
            name: 'Нийт орлого',
            type: 'bar',
            data:   (obs?.dashboard_multi || []).map(el=>{
                return el.SUM
             })
          },
          {
            name: 'Нийт хүсэлт',
            type: 'line',
            yAxisIndex: 1,
            data:  (obs?.dashboard_multi || []).map(el=>{
                return el.Count
             })
          }
        ]
    };
    const option_type = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '5%',
          left: 'center'
        },
        series: [
          {
            name: 'Төрөл',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: obs?.dashboard_single?.Day || 0, name: 'Хоногийн мэдээ' },
              { value: obs?.dashboard_single?.Month || 0, name: 'Сарын мэдээ' },
              { value: obs?.dashboard_single?.Year || 0, name: 'Жилийн мэдээ' },
            ]
          }
        ]
    };

    const [between, setbetween]= useState()
    const handleSingleChange=(e, type)=>{
        if(e){
            let data = {};
            if(typeof e === 'string'){
                data[type] = e;
            } else if((e || [])[0]){
                data.between = data.between || {};
                data.between.start_date = (e || [])[0];
                data.between.end_date = (e || [])[1];
            } else {
                data[e.target.name] = e.target.value;
            }
            setbetween(data)
        }
    }
    const onfilter=()=>{
        props.dispatch(getDashboardSingle(between))
        props.dispatch(getDashboardMulti(between))
    }
    useEffect(()=>{
        props.dispatch(getDashboardSingle(between))
        props.dispatch(getDashboardMulti(between))
    },[])

    return (
        <Row className='flex justify-center md:justify-between'  gutter={[16,16]}>
            <Col className='flex justify-start items-center' span={24}>
                <RangePicker defaultValue={[dayjs((between || {}).start_date || dayjs()), dayjs((between || {}).end_date || dayjs())]} onChange={(e) => handleSingleChange(e, 'between')} />
                <Button type='primary' className="ml-2 bg-blue-500 text-white border-none hover:bg-blue-400" onClick={()=>onfilter()} >Харах</Button>
            </Col>
            <Col >
                <Card title="Нийт хэрэглэгч" className='w-64' size="small" >
                    <Statistic value={obs?.dashboard_single?.usrs || 0} />
                </Card>
            </Col>
            <Col>
                <Card title="Нийт орлого" className='card-stat w-64' size="small">
                    <div className='flex justify-between items-end'>
                        {/* <Statistic
                            value={9.3}
                            precision={2}
                            valueStyle={{
                                color: '#cf1322',
                            }}
                            prefix={<ArrowDownOutlined />}
                            suffix="%"
                        /> */}
                        <Statistic prefix={"₮"} value={`${obs?.dashboard_single?.pay_amount || 0}`} />
                    </div>
                </Card>
            </Col>

            <Col >
                <Card title="Нийт хүсэлт" bordered={false} className='card-stat w-64' size="small">
                    <div className='flex justify-between items-end'>
                        {/* <Statistic
                        value={11.28}
                        precision={2}
                        valueStyle={{
                            color: '#3f8600',
                        }}
                        prefix={<ArrowUpOutlined />}
                        suffix="%"
                        /> */}
                        <Statistic value={obs?.dashboard_single?.all_req || 0} />
                    </div>
                </Card>
            </Col>
            <Col >
                <Card title="Нийт биелсэн хүсэлт" bordered={false} className='card-stat w-64' size="small">
                    <div className='flex justify-between items-end'>
                        {/* <Statistic
                        value={11.28}
                        precision={2}
                        valueStyle={{
                            color: '#3f8600',
                        }}
                        prefix={<ArrowUpOutlined />}
                        suffix="%"
                        /> */}
                        <Statistic value={obs?.dashboard_single?.req_paid || 0} />
                    </div>
                </Card>
            </Col>
            <Col  xs={24} sm={24} md={24} lg={24} xl={16}>
                <Card>
                    <ReactEcharts style={{height:400}} option={option} />
                </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                <Card>
                    <ReactEcharts style={{height:400}} option={option_type} />
                </Card>
            </Col>
            {/* <Col>
                <Card title="Сүүлийн мэдээ авсан огноо" className='w-64' size="small" >
                    <Statistic value={'2023-06-08'} />
                </Card>
             
            </Col>
            <Col>
                <Card title="Сүүлийн мэдээ авсан хэрэглэгч" className='w-64' size="small" >
                    <Statistic value={'Х. Али'} />
                </Card>
            </Col>
            <Col>
                <Card title="Сүүлийн мэдээ авсан мөнгөн дүн" className='w-64' size="small" >
                    <Statistic value={'Х. Али'} />
                </Card>
            </Col>
            <Col>
                <Card title="Сүүлийн авсан мэдээний төрөл" className='w-64' size="small" >
                    <Statistic value={'Жил'} />
                </Card>
            </Col> */}
        </Row>
    )

}
export default connect(reducer)(Main);