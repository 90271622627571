import React, { useEffect, useState } from 'react';
import { Dropdown, Space, Avatar, Button, Spin, Badge } from "antd"
import { DownOutlined, SmileOutlined, UserOutlined  } from "@ant-design/icons"
import { getLogin, LogOut } from '../redux/actions/userAction';
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';

const reducer = ({ user }) => ({  user });

const User=(props)=>{
    const { user:{ loading } } = props
    const [usr, setUsr]= useState({})
    const navigate = useNavigate();
    
    useEffect(()=>{
        props.dispatch(getLogin()).then(c=>{
            setUsr(c)
        })
    },[])

    const lout=()=>{
        props.dispatch(LogOut()).then(c=>{
           if(!c.loggedIn){
             navigate("/login")
           }
        })
    }
    const items = [
        {
          key: '1',
          danger: true,
          label: <p onClick={()=>lout()}>Гарах</p>,
        },
    ];
    return(
        <div className="flex items-start first:pt-0 last:pb-0 text-sm m-4 cursor-pointer">
            <Dropdown
                menu={{
                items,
            }}
            >
                <a className='h-auto' onClick={(e) => e.preventDefault()}>
                    <Space>
                        <Avatar icon={<UserOutlined />} />
                        <div className="overflow-hidden hidden sm:flex flex-col">
                            <p className="text-sm font-medium text-slate-900">{
                                `${usr.auth?.lastname?.split('')[0]}. ${usr.auth?.firstname}`
                            }</p>
                            <p className="text-sm text-slate-500 truncate">{
                                usr.auth?.email
                            }</p>
                        </div>
                    </Space>
                </a>
            </Dropdown>
        </div>
    )
}

export default connect(reducer)(User);