import * as api from '../api';
import * as constants from "../actionTypes";

export const createInvoice = (props)=> async (dispatch)=> {
    try {
        dispatch({
            type: constants.createInvoice.REQUEST
        })
        const { data } = await api.createInvoice(props);
        dispatch ({
            type: constants.createInvoice.RESPONSE,
            payload:data 
        });
        return data
    } catch (error) {
        console.log(error.message)
    }
}

export const checkPayment= (props)=> async (dispatch)=> {
    try {
        dispatch({
            type: constants.checkPayment.REQUEST
        })
        const { data } = await api.checkPayment(props);
        dispatch ({
            type: constants.checkPayment.RESPONSE,
            payload:data 
        });
        return data
    } catch (error) {
        console.log(error.message)
    }
}
export const getInvoice= (props)=> async (dispatch)=> {
    try {
        dispatch({
            type: constants.getInvoice.REQUEST
        })
        const { data } = await api.getInvoice(props);
        dispatch ({
            type: constants.getInvoice.RESPONSE,
            payload:data 
        });
        return data
    } catch (error) {
        console.log(error.message)
    }
}
export const getPaymentList= (props)=> async (dispatch)=> {
    try {
        dispatch({
            type: constants.getPaymentList.REQUEST
        })
        const { data } = await api.getPaymentList(props);
        dispatch ({
            type: constants.getPaymentList.RESPONSE,
            payload:data 
        });
        return data
    } catch (error) {
        console.log(error.message)
    }
}
export const callback_url= (props)=> async (dispatch)=> {
    try {
        
        const { data } = await api.callback_url();
        dispatch ({
            type: constants.callback_url.RESPONSE,
            payload:data 
        });
        return data
    } catch (error) {
        console.log(error.message)
    }
}
export const Check_callback_url= (props)=> async (dispatch)=> {
    try {
        dispatch({
            type: constants.Check_callback_url.REQUEST
        })
        const { data } = await api.Check_callback_url(props);
        dispatch ({
            type: constants.Check_callback_url.RESPONSE,
            payload:data 
        });
        return data
    } catch (error) {
        console.log(error.message)
    }
}