export const addUser = {
    REQUEST: "addUser_REQ",
    RESPONSE: "addUser_RES"
};

export const updateUser = {
    REQUEST: "updateUser_REQ",
    RESPONSE: "updateUser_RES"
};

export const getUsers = {
    REQUEST: "getUsers_REQ",
    RESPONSE: "getUsers_RES"
};
export const getUser = {
    REQUEST: "getUser_REQ",
    RESPONSE: "getUser_RES"
};

export const getLogin = {
    REQUEST: "getLogin_REQ",
    RESPONSE: "getLogin_RES"
};
export const login = {
    REQUEST: "login_REQ",
    RESPONSE: "login_RES"
};
export const LogOut = {
    REQUEST: "LogOut_REQ",
    RESPONSE: "LogOut_RES"
};
export const forgotPassword = {
    REQUEST: "forgotPassword_REQ",
    RESPONSE: "forgotPassword_RES"
};

export const getAimag = {
    REQUEST: "getAimag_REQ",
    RESPONSE: "getAimag_RES"
};
export const getRequest = {
    REQUEST: "getRequest_REQ",
    RESPONSE: "getRequest_RES"
};
export const getRequests = {
    REQUEST: "getRequests_REQ",
    RESPONSE: "getRequests_RES"
};

export const sendToMail = {
    REQUEST: "sendToMail_REQ",
    RESPONSE: "sendToMail_RES"
};
export const downToMail = {
    REQUEST: "downToMail_REQ",
    RESPONSE: "downToMail_RES"
};
export const getSettings = {
    REQUEST: "getSettings_REQ",
    RESPONSE: "getSettings_RES"
};
export const updateSettings = {
    REQUEST: "updateSettings_REQ",
    RESPONSE: "updateSettings_RES"
};



export const createInvoice = {
    REQUEST: "createInvoice_REQ",
    RESPONSE: "createInvoice_RES"
};
export const checkPayment = {
    REQUEST: "checkPayment_REQ",
    RESPONSE: "checkPayment_RES"
};
export const getInvoice = {
    REQUEST: "getInvoice_REQ",
    RESPONSE: "getInvoice_RES"
};
export const getPaymentList = {
    REQUEST: "getPaymentList_REQ",
    RESPONSE:"getPaymentList_RES"
};

export const getDashboardSingle = {
    REQUEST: "getDashboardSingle_REQ",
    RESPONSE:"getDashboardSingle_RES"
};
export const getDashboardMulti = {
    REQUEST: "getDashboardMulti_REQ",
    RESPONSE:"getDashboardMulti_RES"
};
export const callback_url = {
    REQUEST: "callback_url_REQ",
    RESPONSE:"callback_url_RES"
};

export const Check_callback_url = {
    REQUEST: "Check_callback_url_REQ",
    RESPONSE:"Check_callback_url_RES"
};
export const checkDateData = {
    REQUEST: "checkDateData_REQ",
    RESPONSE:"checkDateData_RES"
};

