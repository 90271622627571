import { DatePicker, Select, Button, Steps, Modal, Space, Table, Tag, InputNumber, Input,  Form, Spin, message, Popover  } from "antd"
import { SendOutlined, DownloadOutlined, EditOutlined   } from "@ant-design/icons"
import dayjs from 'dayjs';
import { getAimag, getRequest, getRequests, getSettings, updateSettings, sendToMail, downToMail } from '../redux/actions/obsAction';
import { getUsers, updateUser} from '../redux/actions/userAction';
import { getPaymentList} from '../redux/actions/payAction';
import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import Main from "./Main";
import moment from "moment"
import FileDownload from "js-file-download";

const reducer = ({ user, obs, pay }) => ({  user, obs, pay });
const { RangePicker } = DatePicker;

const Card=(props)=>{
    const [checkPay, setCheckPay] = useState("");
    const [form] = Form.useForm();
    const { user, obs, pay } = props
    const [modal, setModal]= useState(false)
    const [userId,setUserId]= useState(0)
    useEffect(()=>{
        props.dispatch(getRequests())
        props.dispatch(getUsers())
        props.dispatch(getSettings())
    },[])

    const onFinish = (values) => {
      if(values.email && userId>0){
        props.dispatch(updateUser({values,id:userId})).then(c=>{
          if( c.success ){
              message.info(c.message , 5);
              setModal(false)
          }
        })
      }
    };

    const editUsers =(value)=>{
      setUserId(value.id)
      form.setFieldsValue({
        email: value.email,
        firstname: value.firstname,
        lastname: value.lastname,
        phone: value.phone,
     });
      setModal(true)
    }


    const onDownloadFile=(val)=>{
      if(checkPay?.checkPayment?.count>0){
          let request = checkPay.request 
          props.dispatch(downToMail(request)).then(res=>{
              FileDownload(res,`${request.id}.${request.fileType}`)
          })
      }
      if(val?.id>0){
          let request = val
          props.dispatch(downToMail(request)).then(res=>{
              FileDownload(res,`${request.id}.${request.fileType}`)
          })
      }
  }


    const columns = [
      {
        title: '№',
        key: 'number',
        render:(item, record, index)=>(<>{index+1}</>)
      },
        {
            title: 'Хэрэглэгчийн нэр',
            dataIndex: 'firstname',
            key: 'firstname',
            // render: (text) => <a>{text}</a>,
            responsive: ['xl'],
          },
        {
            title: 'Утасны дугаар',
            dataIndex: 'phone',
            key: 'phone',
            // render: (text) => <a>{text}</a>,
        },
        {
            title: 'Имайл',
            dataIndex: 'email',
            key: 'email',
            responsive: ['sm'],
            // render: (text) => <a>{text}</a>,
        },
         {
            title: 'Индекс',
            dataIndex: 'sums',
            key: 'sums',
            responsive: ['xl'],
            render: (record) => 
            (
              (record || []).split(',').length ===1 ? record : `${(record || []).split(',')[0]}..`                       
            )
        },
        {
          title: 'Төлбөрийн төлөв',
          key: 'pay_status',
          dataIndex: 'pay_status',
          responsive: ['sm'],
          filters: [
            {
              text: 'Төлөгдөөгүй',
              value: 'pending',
            },
            {
              text: 'Төлсөн',
              value: 'paid',
            }],
            filterSearch: true,
            onFilter: (value, record) => record.pay_status.includes(value),
          render: (_, { pay_status }) => (
            <>
            {
                (
                    <Tag color={pay_status==="pending"? "red":'green'} key={pay_status}>
                      {pay_status==="pending"? "Төлөгдөөгүй":'Төлсөн'}
                    </Tag>
                  )
            }
            </>
          ),
        },
        {
          title: 'Төлөрийн дүн',
          dataIndex: 'pay_amount',
          key: 'pay_amount',
          render: (value) => <>{
            value
          }₮</>,
          responsive: ['lg'],
        },
        
        // {
        //     title: 'Эхлэх хугацаа',
        //     dataIndex: 'start_date',
        //     key: 'start_date',
        //     responsive: ['xl'],
        // },
        // {
        //     title: 'Дууссах хугацаа',
        //     dataIndex: 'end_date',
        //     key: 'end_date',
        //     responsive: ['xl'],
        // },
        {
            title: 'Үүсгэсэн хугацаа',
            dataIndex: 'created',
            key: 'created',
            responsive: ['md'],
            // render: (date) => <>{date}</>,
            render: (date) => <>{dayjs(date).add(-8,"hours").format("YYYY-MM-DD HH:mm")}</>,
        },
        {
          title: 'Үйлдэл',
          key: 'action',
          render: (_, record) => (
            <Space direction="horizontal"  size="small">
                <Popover  title="Татаж авах">
                    <Button onClick={()=>onDownloadFile(record)} shape="circle" type="primary" className='border-none bg-blue-500 text-white rounded-md w-full hover:bg-blue-300 hover:text-white duration-300 flex justify-center items-center' icon={<DownloadOutlined />} ></Button>
                </Popover>
            </Space>
          ),
        },
    ];
    const columnsUsers = [
      {
        title: '№',
        key: 'number',
        render:(item, record, index)=>(<>{index+1}</>)
      },
      {
          title: 'Хэрэглэгчийн дугаар',
          dataIndex: 'id',
          key: 'id',
          responsive: ['md'],
      },
      {
          title: 'Овог нэр',
          dataIndex: 'lastname',
          key: 'lastname',
          render: (text) => <>{text}</>,
          responsive: ['md'],
        },
      {
          title: 'Өөрийн нэр',
          dataIndex: 'firstname',
          key: 'firstname',
          render: (text) => <>{text}</>,
          responsive: ['md'],
        },
      {
          title: 'Утасны дугаар',
          dataIndex: 'phone',
          key: 'phone',
          render: (text) => <>{text}</>,
      },
      {
          title: 'Имайл',
          dataIndex: 'email',
          key: 'email',
          render: (text) => <>{text}</>,
          responsive: ['sm'],
      },
      {
          title: 'Бүртгүүлсэн хугацаа',
          dataIndex: 'created',
          key: 'created',
          responsive: ['lg'],
          render: (date) => <>{dayjs(date).format("YYYY-MM-DD HH:mm")}</>,
      },
      {
        title: 'Үйлдэл',
        key: 'action',
        responsive: ['lg'],
        render: (_, record) => (
          <Space direction="horizontal"  size="small">
              <Button onClick={()=>editUsers(record)} type="primary" className='border-none bg-blue-500 text-white rounded-md w-full hover:bg-blue-300 hover:text-white duration-300 flex justify-center items-center' icon={<DownloadOutlined />} >Засах</Button>
          </Space>
        ),
      },
    ];
    const columnsSettings = [
      {
        title: '№',
        key: 'number',
        render:(item, record, index)=>(<>{index+1}</>),
        responsive: ['sm'],
      },
      {
          title: 'Мэдээний төрөл',
          dataIndex: 'type',
          key: 'type',
          render: (value) => (
            <>
            {
                (
                    <Tag color={value==="year"? "red": value==='month' ? 'orange'  : value==='definition' ? 'magenta':'blue'} key={value}>
                      {value==="year"? "Жил": value==='month' ? 'Сар' : value==='definition' ? 'Тодорхойлт' :'Өдөр'}
                    </Tag>
                  )
            }
            </>
          ),
      },
      {
          title: 'Мэдээний төлбөр',
          dataIndex: 'value',
          key: 'value',
          responsive: ['sm'],
          render: (value) => <>{
            value
          }₮</>,
        },
      {
          title: 'Үүсгэсэн хугацаа',
          dataIndex: 'created',
          key: 'created',
          responsive: ['sm'],
          render: (date) => <>{dayjs(date).add(-8,"hours").format("YYYY-MM-DD HH:mm")}</>,
      },
      {
        title: 'Үйлдэл',
        key: 'action',
        render: (_, record) => (
          <Space direction="horizontal"  size="small">
              <div className="hidden sm:flex">
                  <Button onClick={()=>editSettings(record)} size="small" type="primary" className='border-none bg-blue-500 text-white rounded-md w-full hover:bg-blue-300 hover:text-white duration-300 flex justify-center items-center' icon={<EditOutlined />} >Засах</Button>
              </div>
              <div className="sm:hidden flex">
                  <Button shape="circle" onClick={()=>editSettings(record)} size="small" type="primary" className='border-none bg-blue-500 text-white rounded-md w-full hover:bg-blue-300 hover:text-white duration-300 flex justify-center items-center' icon={<EditOutlined />} ></Button>
              </div>
          </Space>
        ),
      },
    ];
    const columnsList = [
      {
        title: '№',
        key: 'number',
        render:(item, record, index)=>(<>{index+1}</>)
      },
        {
            title: 'Төлбөрийн дугаар',
            dataIndex: 'payment_id',
            key: 'payment_id',
            responsive: ['sm'],
          },
          {
            title: 'Төлөрийн дүн',
            dataIndex: 'payment_amount',
            key: 'payment_amount',
            render: (value) => <>{
              value
            }₮</>,
          },
        {
          title: 'Төлбөрийн төлөв',
          key: 'payment_status',
          dataIndex: 'payment_status',
          responsive: ['sm'],
          render: (_, { payment_status }) => (
            <>
            {
                (
                    <Tag color={payment_status==="pending"? "red":'green'} key={payment_status}>
                      {payment_status==="pending"? "Төлөгдөөгүй":'Төлсөн'}
                    </Tag>
                  )
            }
            </>
          ),
        },
      
        {
          title: 'Гүйлгээний утга',
          dataIndex: 'payment_description',
          key: 'payment_description',
          responsive: ['md'],
        },
        {
            title: 'Төлсөн хугацаа',
            dataIndex: 'payment_date',
            key: 'payment_date',
            render: (date) => <>{dayjs(date).format("YYYY-MM-DD HH:mm:ss")}</>,
        }
    ];
   
   
    const onFilter=()=>{
      if(between?.between){
        if (props.selected==="requests"){
          props.dispatch(getRequests({date:between.between}))
        } else if (props.selected==="users") {
          props.dispatch(getUsers({date:between.between}))
        } else if (props.selected==="paylists") {
          props.dispatch(getPaymentList({date:between.between}))
        }
      }
    }
    const [between, setbetween]= useState()
    const handleSingleChange=(e, type)=>{
        if(e){
            let data = {};
            if(typeof e === 'string'){
                data[type] = e;
            } else if((e || [])[0]){
                data.between = data.between || {};
                data.between.start_date = (e || [])[0];
                data.between.end_date = (e || [])[1];
            } else {
                data[e.target.name] = e.target.value;
            }
            setbetween(data)
        }
    }

    const [visible,setVisible]=useState(false)
    const [dataSet,setDataSet]=useState({})
    const [value,setValue]=useState(0)

    const editSettings=(val)=>{
      setVisible(true)
      setDataSet(val)
    }
    const hideModal=(val)=>{
      setVisible(val)
    }
    const updateSingle=()=>{
      if(value>0){
        props.dispatch(updateSettings({type:dataSet.type,value:value}))
      }
      setVisible(false)

    }

  
    
    return(
        <Space
          direction="vertical"
          className="w-full"
        >
          {
            props.selected==="settings" || props.selected==="dashboard"?
            null
            :
            <Space direction="horizontal">
              <RangePicker defaultValue={[dayjs((between || {}).start_date || dayjs()), dayjs((between || {}).end_date || dayjs())]} onChange={(e) => handleSingleChange(e, 'between')} />
              <Button type="primary" className="bg-blue-500 border-none text-white hover:bg-blue-400" loading={pay.loading || false} onClick={()=>onFilter()}>Харах</Button>
            </Space>
          }

        {
            props.selected==="requests" ?
            <Table 
                className="w-full h-36" 
                columns={columns} 
                // rowSelection={{}}
                dataSource={obs?.requests || []} 
                expandable={{
                  expandedRowRender: (record) => (
                    <Space
                    direction="vertical"
                    size="small"
                    className="w-auto"
                    
                  >
                    <div>Индекс:{record.sums}</div>
                    <div>Элемент:{record.elements}</div>
                    <div>Мэдээний Хугацаа:{record.start_date}-{record.end_date}</div>
                    <div>Төлбөр:
                      <Tag color={record.pay_status==="pending"? "red":'green'} key={record.pay_status}>
                        {record.pay_status==="pending"? "Төлөгдөөгүй":'Төлсөн'}
                      </Tag>
                    </div>
                    <div>ТөлбөрийнХэжээ:{record.pay_amount}₮</div>
                    </Space>
                  ),
                  // rowExpandable: (record) => 
                  // (
                  //   (record.sums).split(',').length !==1                          
                  // )
                }}
                pagination={{
                    pageSize: 10,
                }}
            />
            : props.selected==="users" ?
            <Table 
                className="w-full h-36" 
                columns={columnsUsers} 
                dataSource={user?.users || []} 
                pagination={{
                    pageSize: 10,
                }}
                expandable={{
                  expandedRowRender: (record) => (
                    <Space
                    direction="vertical"
                    size="small"
                    style={{
                      display: 'flex',
                    }}
                  >
                    <div>Хэрэглэгчийн дугаар:{record.id}</div>
                    <div>Овог нэр:{record.lastname}</div>
                    <div>Өөрийн нэр:{record.firstname}</div>
                    <div>Утасны дугаар:{record.phone}</div>
                    <div>Имайл:{record.email}</div>
                    <div>Бүртгүүлсэн хугацаа:{record.created}</div>
                    <Space direction="horizontal"  size="small">
                        <Button onClick={()=>editUsers(record)} type="primary" className='border-none bg-blue-500 text-white rounded-md w-full hover:bg-blue-300 hover:text-white duration-300 flex justify-center items-center' icon={<DownloadOutlined />} >Засах</Button>
                    </Space>
                    </Space>
                  ),
                  // rowExpandable: (record) => 
                  // (
                  //   (record.sums).split(',').length !==1                          
                  // )
                }}
            />
            : props.selected==="settings" ?
              <>
              <Table 
                className="w-full h-36" 
                columns={columnsSettings} 
                dataSource={obs?.settings || []} 
                pagination={{
                    pageSize: 10,
                }}
                expandable={{
                  expandedRowRender: (record) => (
                    <Space
                      direction="vertical"
                      size="small"
                      style={{
                        display: 'flex',
                      }}
                    >
                      <div>Мэдээний төрөл:{record.type}</div>
                      <div>Мэдээний төлбөр:{record.value}</div>
                      <div>Үүсгэсэн хугацаа:{dayjs(record.created).format("YYYY-MM-DD HH:mm")}</div>
                    </Space>
                  ),
                  // rowExpandable: (record) => 
                  // (
                  //   (record.sums).split(',').length !==1                          
                  // )
                }}
              />
              <Modal title="Тохиргоо" open={visible} onCancel={()=>hideModal(false)}>
                <Tag className="mb-2" color={dataSet.type==="year"? "red": dataSet.type==='month' ? 'orange' : dataSet.type==='definition' ? 'green':'blue'} key={dataSet.type}>
                  {dataSet.type==="year"? "Жил": dataSet.type==='month' ? 'Сар' : dataSet.type==='definition' ? 'Тодорхойлт' :'Өдөр'}
                </Tag>
                <InputNumber value={dataSet.value} onChange={e=>setValue(e)} placeholder="Мөнгөн дүн оруулна" addonAfter="₮" />
                <Button onClick={()=>updateSingle()} type="primary" className="border-none mt-2 bg-blue-500 text-white rounded-md w-full hover:bg-blue-300 hover:text-white duration-300 flex justify-center items-center">Хадгалах</Button>
            </Modal>
            </>
            : props.selected==="paylists"?       
             <Table 
              className="w-full h-36" 
              pagination={{
                  pageSize: 10,
              }}
              columns={columnsList} 
              dataSource={!pay?.loading ? pay?.paymentlist?.rows : []} 
            /> :<Main/>
        }
        <Modal cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }} open={modal} onCancel={()=>setModal(false)} onOk={()=>setModal(false)}>
        <Form  form = {form}
          name="normal_login"
          className="login-form w-full pt-10"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
        <Form.Item
          name="email"
          className="pb-1"
          rules={[
            {
              required: true,
              message: '* Email оруулна уу. ',
            },
            {
              type: 'email',
              message: 'Имайл хаяг оруулна уу.',
            },
          ]}
        >
          <Input type="email" className="py-2" placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="lastname"
          className="pb-1"
          rules={[
            {
              required: true,
              message: '* Овог нэр оруулна уу. ',
            },
          ]}
        >
          <Input  className="py-2" placeholder="Овог нэр" />
        </Form.Item>
        <Form.Item
          name="firstname"
          className="pb-1"
          rules={[
            {
              required: true,
              message: '* Өөрийн нэр оруулна уу. ',
            },
          ]}
        >
          <Input  className="py-2" placeholder="Өөрийн нэр" />
        </Form.Item>
        <Form.Item
          name="phone"
          className="pb-1"
          rules={[
            {
              required: true,
              message: '* Утасны дугаар оруулна уу. ',
            },
          ]}
        >
          <Input type="number" className="py-2" placeholder="Утасны дугаар" />
        </Form.Item>

        {/* <Form.Item
          name="passwordOld"
          className="pb-1"
          hasFeedback
          rules={[
            {
              required: true,
              message: '* Хуучин нууц үг оруулна уу.',
            },
          ]}
        >
          <Input.Password
            className="py-2"
            // prefix={<LockOutlined className="site-form-item-icon" />}
            type="passwordOld"
            placeholder="Хуучин нууц үг"
          />
        </Form.Item> */}

        <Form.Item
          name="password"
          className="pb-1"
          hasFeedback
          rules={[
            {
              required: true,
              message: '* Шинэ нууц үг оруулна уу.',
            },
          ]}
        >
          <Input.Password
            className="py-2"
            // prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Шинэ нууц үг"
          />
        </Form.Item>

        <Form.Item
          name="passwordConfirm"
          dependencies={['password']}
          hasFeedback
          className="pb-1"
          rules={[
            {
              required: true,
              message: '* Шинэ нууц үг оруулна уу.',
            },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Нууц үг адилхан биш байна.'));
            },
          }),
          ]}
        >
          <Input.Password
            className="py-2"
            // prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Шинэ нууц үг давтах"
          />
        </Form.Item>
 

        <Form.Item>
          <div className="flex flex-col">
            <button htmlType="submit" className="focus:ring-1 ring-blue-700 outline-none bg-blue-500 text-white border border-blue-500 hover:bg-blue-400 py-2 rounded-md duration-300">
                Засах
            </button>
          </div>
        </Form.Item>
        </Form>
        </Modal>

      </Space>
      
    )
}

export default connect(reducer)(Card);