import * as api from '../api';
import * as constants from "../actionTypes";

export const getAimag = (props)=> async (dispatch)=> {
    try {
        dispatch({
            type: constants.getAimag.REQUEST
        })
        const { data } = await api.getAimag();
        dispatch ({
            type: constants.getAimag.RESPONSE,
            payload:data 
        });
        return data
    } catch (error) {
        console.log(error.message)
    }
}
export const getRequest = (props)=> async (dispatch)=> {
    try {
        dispatch({
            type: constants.getRequest.REQUEST
        })
        const { data } = await api.getRequest(props);
        dispatch ({
            type: constants.getRequest.RESPONSE,
            payload:data 
        });
        return data
    } catch (error) {
        console.log(error.message)
    }
}
export const getRequests = (props)=> async (dispatch)=> {
    try {
        dispatch({
            type: constants.getRequests.REQUEST
        })
        const { data } = await api.getRequests(props);
        dispatch ({
            type: constants.getRequests.RESPONSE,
            payload:data 
        });
        return data
    } catch (error) {
        console.log(error.message)
    }
}
export const sendToMail = (props)=> async (dispatch)=> {
    try {
        dispatch({
            type: constants.sendToMail.REQUEST
        })
        const { data } = await api.sendToMail(props);
        dispatch ({
            type: constants.sendToMail.RESPONSE,
            payload:data 
        });
        return data
    } catch (error) {
        console.log(error.message)
    }
}
export const downToMail = (props)=> async (dispatch)=> {
    try {
        dispatch({
            type: constants.downToMail.REQUEST
        })
        const { data } = await api.downToMail(props);
        dispatch ({
            type: constants.downToMail.RESPONSE,
            payload:data 
        });
        return data
    } catch (error) {
        console.log(error.message)
    }
}
export const getSettings = (props)=> async (dispatch)=> {
    try {
        dispatch({
            type: constants.getSettings.REQUEST
        })
        const { data } = await api.getSettings(props);
        dispatch ({
            type: constants.getSettings.RESPONSE,
            payload:data 
        });
        return data
    } catch (error) {
        console.log(error.message)
    }
}
export const updateSettings = (props)=> async (dispatch)=> {
    try {
        dispatch({
            type: constants.updateSettings.REQUEST
        })
        const { data } = await api.updateSettings(props);
        dispatch ({
            type: constants.updateSettings.RESPONSE,
            payload:data 
        });
        return data
    } catch (error) {
        console.log(error.message)
    }
}

export const getDashboardSingle = (props)=> async (dispatch)=> {
    try {
        dispatch({
            type: constants.getDashboardSingle.REQUEST
        })
        const { data } = await api.getDashboardSingle(props);
        dispatch ({
            type: constants.getDashboardSingle.RESPONSE,
            payload:data 
        });
        return data
    } catch (error) {
        console.log(error.message)
    }
}

export const getDashboardMulti = (props)=> async (dispatch)=> {
    try {
        dispatch({
            type: constants.getDashboardMulti.REQUEST
        })
        const { data } = await api.getDashboardMulti(props);
        dispatch ({
            type: constants.getDashboardMulti.RESPONSE,
            payload:data 
        });
        return data
    } catch (error) {
        console.log(error.message)
    }
}
export const checkDateData = (props)=> async (dispatch)=> {
    try {
        dispatch({
            type: constants.checkDateData.REQUEST
        })
        const { data } = await api.checkDateData(props);
        dispatch ({
            type: constants.checkDateData.RESPONSE,
            payload:data 
        });
        return data
    } catch (error) {
        console.log(error.message)
    }
}







