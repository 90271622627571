import Logo from "../../icon/logo.png"
import {  message, Form, Input, Checkbox, Spin } from "antd"
import { LockOutlined, UserOutlined  } from "@ant-design/icons"
import { useEffect } from "react";

import { login, getLogin } from '../../redux/actions/userAction';
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
const reducer = ({ user }) => ({  user });

const Login=(props)=>{
  const { user:{ loading } } = props
  const navigate = useNavigate();

  const onFinish = async (values) => {
   if(values.username && values.password){
      props.dispatch(login(values)).then(c=>{
        if( c.success ){
          if ( c.token ){
            message.success('Амжилттай нэвтэрлээ.', 5);
            if(c?.auth?.status==="admin"){
              navigate("/admin")
            } else if(c?.auth?.status==="user") {
              navigate("/request")
            }
          }
        }
        if( c.success === false ) {
          message.warning('Нэвтрэх амжилтгүй боллоо.', 5);
        }
      })
   }
  };
  useEffect(()=>{
    props.dispatch(getLogin()).then(res=>{
        if(res?.auth?.status==="admin"){
          navigate("/admin")
        } else if(res?.auth?.status==="user") {
          navigate("/request")
        }
     })
},[])

  return(
    <Spin spinning={loading || false }>
      <div className='w-full h-auto sm:h-screen flex justify-center items-center text-sm'>
        <div className="max-w-xl h-screen sm:h-96 bg-white p-8 sm:rounded-2xl border border-slate-300 drop-shadow-md flex flex-col justify-start items-start w-full">
          <div className="flex text-2xl font-bold mb-8">
            <Link
              to="/">
              <img
                className="w-32"
                src={Logo}
              />
            </Link>
          </div>
          <Form
            name="normal_login"
            className="login-form w-full"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
          <Form.Item
            name="username"
            className="pb-2"
            rules={[
              {
                required: true,
                message: '* Email эсвэл утасны дугаараа оруулна уу. ',
              },
            ]}
          >
            <Input className="py-2" prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email or Phone" />
          </Form.Item>
          <Form.Item
            name="password"
            className="pb-2"
            rules={[
              {
                required: true,
                message: '* Нууц үг оруулна уу.',
              },
            ]}
          >
            <Input
              className="py-2"
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Нууц үг"
            />
          </Form.Item>
          <Form.Item className="mb-2">
            <div className="sm:flex justify-between">
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Намайг санах</Checkbox>
              </Form.Item>
              <Link className="login-form-forgot" to="/reset-password">
                Нууц үг мартсан
              </Link>
            </div>
          </Form.Item>

          <Form.Item>
            <div className="flex flex-col mb-2">
              <button onClick={onFinish} htmltype="submit" className="bg-blue-500 text-white border border-blue-500 hover:bg-blue-400 py-2 rounded-md duration-300">
                Нэвтрэх
              </button>
            </div>
              <Link className="w-auto" to="/register">Бүртгүүлэх</Link>
          </Form.Item>
          </Form>
        </div>
      </div>
    </Spin>
  )
    

}
export default connect(reducer)(Login)