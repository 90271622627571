import { AiFillYoutube, AiOutlineTwitter, AiFillFacebook } from 'react-icons/ai';
import { Link } from 'react-router-dom';
const Footer=()=>{
    return(
      <>
        <div className="bg-slate-600 text-white border-none py-2 px-4 text-xs h-full">
            <div className="container mx-auto lg:flex lg:justify-between lg:items-start w-full">
                    <ul className="my-4 w-full lg:w-1/3 lg:mr-10">
                        <li className="font-bold mb-2 border-b border-b-white pb-2">ХОЛБОО БАРИХ</li>
                        <li className="my-3 text-white cursor-pointer">
                            <a className='hover:border-b'>Утас: 51-266149</a>
                        </li>
                        <li className="my-3 text-white cursor-pointer">
                            <a className='hover:border-b'>Имэйл:archivemeteo@gmail.com</a>
                        </li>
                        <li className="my-3 text-white">
                            Хаяг:Улаанбаатар-15160 Чингэлтэй дүүрэг, 4-р хороо, Жуулчны гудамж-5, Бага тойруу-3 Цаг уур, орчны шинжилгээний газар
                        </li>
                    </ul>
                    <ul className="my-4 w-full lg:w-1/3 lg:mr-10">
                        <li className="font-bold mb-2 border-b border-b-white pb-2">ХОЛБООС</li>
                        {/* <li className="my-3 cursor-pointer">
                            <a className='hover:border-b'>Төрийн байгууллагуудын вэб хуудас</a>
                        </li> */}
                        <li className="my-3 cursor-pointer">
                            <a href='https://met.gov.mn/' target="_blank" className='hover:border-b'>Байгаль орчин, аялал жуулчлалын яам</a>
                        </li>
                        <li className="my-3 cursor-pointer">
                            <a href='http://tsag-agaar.mn/' target="_blank" className='hover:border-b'>Цаг уур орчны, шинжилгээний газар</a>
                        </li>
                        <li className="my-3 cursor-pointer">
                            <a href='http://irimhe.namem.gov.mn/' target="_blank" className='hover:border-b'>Ус цаг уур орчны судалгаа, мэдээллийн хүрээлэн</a>
                        </li>
                    </ul>
                    <ul className="my-4 w-full lg:w-1/3">
                        <li className="font-bold mb-2 border-b border-b-white pb-2">БУСАД</li>
                        <li className="my-3 cursor-pointer"><Link target="_blank" className='flex items-center justify-start hover:border-b w-auto' to='https://www.youtube.com/@mongolianweather1588'><AiFillYoutube className='text-lg mr-2'/> Youtube</Link></li>
                        <li className="my-3 cursor-pointer"><Link target="_blank" className='flex items-center justify-start hover:border-b w-auto' to='https://www.facebook.com/Mongolianweather'><AiFillFacebook className='text-lg mr-2'/> FaceBook</Link></li>
                        <li className="my-3 cursor-pointer"><Link target="_blank" className='flex items-center justify-start hover:border-b w-auto' to='https://www.facebook.com/Mongolianweather'><AiOutlineTwitter className='text-lg mr-2'/> Twitter</Link></li>
                    </ul>
            </div>
       </div>
        <div>
            <p className="m-0 p-0 text-center bg-slate-900 text-white text-xs">© 2023 он Цаг уур, орчны шинжилгээний газар</p>
        </div>
      </>
    )

}

export default Footer;