import Side from "./SideMenu";
import User from './User';
import Card from './Card';
import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { getLogin } from '../../redux/actions/userAction';
import { useNavigate } from 'react-router-dom';
import { Spin, Skeleton } from "antd"

const reducer = ({ user }) => ({  user });
const Pages=(props)=>{
    const { user } = props
    const navigate = useNavigate();
    const [spinStatusm, setSpinStatus]=useState(true)

    useEffect(()=>{
        const fetchData = async () => {
            const res = await props.dispatch(getLogin())
            if (!res) {
                return navigate("/login")
            }
            if(!res?.loggedIn){
                return navigate("/login")
            }
            if(res?.loggedIn){
                setSpinStatus(false)
            }

        };
        fetchData();

        // props.dispatch(getLogin()).then(res=>{
        //     if(res===undefined){
        //         return navigate("/login")
        //     }
        //     if(!res?.loggedIn){
        //         navigate("/login")
        //     }
        //  })
    },[])
    

    return(
        <Spin spinning={spinStatusm}>
        <Skeleton loading={spinStatusm} />
        <div className='grid grid-rows-6 grid-cols-6 gap-4'>
                <div className="xl:row-span-6 col-span-1"><Side selected={props.selected}/></div>
                <div className="xl:row-span-1 col-span-5 flex justify-end"><User user={user}/></div>
                <div className="row-span-6 xl:row-span-5 col-span-6 xl:col-span-5 flex justify-start p-4"><Card selected={props.selected}/></div>
        </div>
        </Spin>
    )
}

export default connect(reducer)(Pages);