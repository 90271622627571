import React, { useState } from 'react';
import {  Form, Input, message, Checkbox } from "antd"
import Logo from "../../icon/logo.png"
import { addUser } from '../../redux/actions/userAction';
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
const reducer = ({ user }) => ({  user });

const Login=(props)=>{
  const navigate = useNavigate();
  const [check, setCheck]= useState(false)
  const onChange=(e)=>{
    console.log(e.target.checked)
    setCheck(e.target.checked)
  }
  
  const onFinish = (values) => {
    if(values.email){
      props.dispatch(addUser(values)).then(c=>{
        if( c.success ){
          if(c.user[0].Expr1 === -1){
            message.warning('Хэрэглэгч бүртгэлтэй байна.', 5);
          }
          if ( c.user[0].id ){
            message.success('Амжилттай бүртгүүллээ.', 5);
            navigate('/login')
          }
        }
      })
    }
  };
  return(
    <div className='w-full h-auto sm:h-screen flex justify-center items-center text-sm'>
      <div className="max-w-xl h-screen sm:h-auto bg-white p-8 sm:rounded-2xl border border-slate-300 drop-shadow-md flex flex-col justify-start items-start w-full">
        <div className="flex text-2xl font-bold mb-8">
          <Link
            to="/">
            <img
              className="w-32"
              src={Logo}
            />
          </Link>
        </div>
        <Form
          name="normal_login"
          className="login-form w-full"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
        <Form.Item
          name="email"
          className="pb-1"
          rules={[
            {
              required: true,
              message: '* Email оруулна уу. ',
            },
            {
              type: 'email',
              message: 'Имайл хаяг оруулна уу.',
            },
          ]}
        >
          <Input type="email" className="py-2" placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="lastname"
          className="pb-1"
          rules={[
            {
              required: true,
              message: '* Овог нэр оруулна уу. ',
            },
          ]}
        >
          <Input  className="py-2" placeholder="Овог нэр" />
        </Form.Item>
        <Form.Item
          name="firstname"
          className="pb-1"
          rules={[
            {
              required: true,
              message: '* Өөрийн нэр оруулна уу. ',
            },
          ]}
        >
          <Input  className="py-2" placeholder="Өөрийн нэр" />
        </Form.Item>
        <Form.Item
          name="phone"
          className="pb-1"
          rules={[
            {
              required: true,
              message: '* Утасны дугаар оруулна уу. ',
            },
          ]}
        >
          <Input type="number" className="py-2" placeholder="Утасны дугаар" />
        </Form.Item>

        <Form.Item
          name="password"
          className="pb-1"
          hasFeedback
          rules={[
            {
              required: true,
              message: '* Нууц үг оруулна уу.',
            },
            {
              min:4,
              message: '* Хамгийн багадаа 4 тэмдэгт байх ёстой',
            },
          ]}
        >
          <Input.Password
            className="py-2"
            // prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Нууц үг"
          />
        </Form.Item>

        <Form.Item
          name="passwordConfirm"
          dependencies={['password']}
          hasFeedback
          className="pb-1"
          rules={[
            {
              required: true,
              message: '* Нууц үг оруулна уу.',
            },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Нууц үг адилхан биш байна.'));
            },
          }),
          ]}
        >
          <Input.Password
            className="py-2"
            // prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Нууц үг давтах"
          />
        </Form.Item>
        <Form.Item>
          <div className='flex justify-center items-start text-justify'>
            <Checkbox onChange={onChange}>
            </Checkbox>
            <div className='ml-2'>
              Бид хэрэглэгчийн хувийн мэдээллийг зөвхөн энэ хуудасны үйл ажиллагааг сайжруулах, аюулгүй, найдвартай ажиллагааг дэмжих зорилгоор ашиглана.
            </div>
          </div>
        </Form.Item>

        <Form.Item>
          <div className="flex flex-col">
            {
              check &&
              <button htmlType="submit" className="focus:ring-1 ring-blue-700 outline-none bg-blue-500 text-white border border-blue-500 hover:bg-blue-400 py-2 rounded-md duration-300">
                Бүртгүүлэх
              </button>
            }

          </div>
        </Form.Item>
        </Form>
      </div>
    </div>
  )
    

}
export default  connect(reducer)(Login)