import { Link } from "react-router-dom";
import Logo from "../../icon/logo.png"
import Manual from '../../ifile/Гарын-авлага.pdf';
const Header=()=>{
    return(
        <div className="bg-white border-b border-b-slate-300  py-4 px-4 text-md font-bold cursor-pointer text-gray-600">
            <div className=" container mx-auto flex flex justify-between items-center">
                <Link
                to="/">
                    <img
                        className="w-32"
                        src={Logo}
                        />
                </Link>
                <div className="flex flex-col sm:flex-row items-center">
                    <div className="sm:border-r sm:border-r-slate-500  pr-4">
                        <a type="application/pdf" target="_blank" className="hover:border-b border-gray-600" href={Manual}>Гарын авлага</a>
                    </div>
                    <div className="pl-4">
                        <Link
                            to="/login">
                            <div className="hover:border-b border-gray-600">Нэвтрэх</div>
                        </Link>
                    </div>
                </div>
           
            </div>
        </div>
    )

}

export default Header;