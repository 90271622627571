import { DatePicker, Select, Button, Steps, QRCode, Space, Table, Tag, Form, Popover, Spin, message, InputNumber  } from "antd"
import { SendOutlined, DownloadOutlined  } from "@ant-design/icons"
import dayjs from 'dayjs';
import { getAimag, getRequest, getRequests, sendToMail, downToMail, getSettings, checkDateData } from '../../redux/actions/obsAction';
import { createInvoice, checkPayment, getInvoice, Check_callback_url } from '../../redux/actions/payAction';
import { useState, useEffect } from 'react';
import { connect } from "react-redux";
import QpayLogo from "../../icon/qpaypayment.svg"
import { Link } from "react-router-dom";
import FileDownload from "js-file-download";
const reducer = ({ user, obs, pay }) => ({  user, obs, pay });

const Card=(props)=>{
    const { user, obs, pay } = props

    const { Option } = Select;
    const [dateFormat, setDateFormat] = useState('YYYY-MM-DD')
    const [dateType, setDateType] = useState('date')
    const [aimags, setAimags] = useState([])
    const [createInvo,setCreateInvo] = useState({})

    const [selValue, setSelValue] = useState(0);
    const [reqId, setReqId] = useState(0);
    const [invId, setInvId] = useState(0);
    const [checkPay, setCheckPay] = useState("");
    const onChange = (value) => {
      setSelValue(value)
    };
    
    const onSearch = (value) => {
      setSelValue(value)
    };

    const createPayment=()=>{
        props.dispatch(createInvoice({id:reqId,amount:amount})).then(c=>{
            setCreateInvo(c)
           if(c.createInvoice?.invoice_id){
                setInvId(c.createInvoice?.invoice_id)
                next()
           }
        })
    }
    const checkPaymentClick=()=>{
        if(invId){
            props.dispatch(checkPayment({id:invId})).then(c=>{
            setCheckPay(c)
            if(c.checkPayment?.count>0){
                next()
            }
           })
        }else{
            return message.warning('Нэхэмжлэл үүсээгүй байна', 5);
        }

    }

    // const backCallInovice=(e)=>{
    //     if(e){
    //         console.log(e.invoice_id)
    //         props.dispatch(getInvoice({id:e.invoice_id})).then(c=>{
    //             console.log(c)
    //         })
    //     }
       
    // }

    const options = [
        {
            value: "tt_ave",
            label: "Агаарын дундаж температур"
        }, 
        {
            value: "tt_max",
            label: "Агаарын хамгийн их температур"
        }, 
        {
            value: "tt_min",
            label: "Агаарын хамгийн бага температур"
        }, 
        {
            value: "wind_ave",
            label: "Салхины дундаж хурд температур"
        }, 
        {
            value: "wind_max",
            label: "Салхины хамгийн их хурд температур"
        }, 
        {
            label: "Хөрсний хамгийн их температур",
            value: "soil_tmax"
        }, 
        {
            label: "Хөрсний хамгийн бага температур",
            value: "soil_tmin"
        }, 
        {
            label: "Хөрсний дундаж температур",
            value: "soil_tave"
        }, 
        {
            label: "Дундаж харьцангуй чийг",
            value: "humd_ave"
        }, 
        {
            label: "Хамгийн бага харьцангуй чийг",
            value: "humd_min"
        }, 
        {
            label: "Станцын түвшний даралт",
            value: "spres"
        }, 
        {
            label: "Далайн түвшний даралт",
            value: "slp"
        }, 
        {
            label: "Хур тунадасны нийлбэр",
            value: "rr_sum"
        }, 
    ];
    const options_time = [
        {
            value: "year",
            label:"Жил"
        }, 
        {
            value: "month",
            label:"Сар"
        }, 
        {
            value: "day",
            label:"Өдөр"
        }      
    ];

    const options_type= [
        {
            value:"txt",
            label: "Text",
        }, 
        {
            value:"csv",
            label: "CSV",
        }
    ];


    const changeTimeType=(value)=>{
        if(value==="year"){
            setDateFormat('YYYY')
            setDateType(value)
        }
        if(value==="month"){
            setDateFormat('YYYY-MM')
            setDateType(value)
        }
        if(value==="day"){
            setDateFormat('YYYY-MM-DD')
            setDateType("date")
        }
    }
    const [form] = Form.useForm();
    const [amount, setAmount] =useState(0)
    const onFinish = (values) => {
        if(values.start_date>values.end_date){
            return message.warning('Хугацаа буруу байна. Эхлэх хугацаа дуусах хугацаагаас бага байх ёстой', 5);
        }
        props.dispatch(getRequest(values)).then(c=>{
            if(!c.success){
                return message.warning('Таны сонгосон сум болон хугацааны мэдээ байхгүй байна', 5);
            }else{
                setAmount(c.request[0]?.pay_amount)
                setReqId(c.request[0]?.id)
                next()
            }
        })
    };

    const amg = async() => {
        const options_ind =[]
        await props.dispatch(getAimag()).then(c=>{
            if(c.success){
                (c.aimags || []).map(el=>{
                    options_ind.push(
                        {
                            label:`${el.aimag}, ${el.sum} [${el.station_id}]`,
                            value:el.station_id
                        }, 
                    )
                })
                setAimags(c.aimags)
            }
     
        })
        // setAimags(options_ind)
    }
    const [callbackStatus, setCallbackStatus] = useState(0)
    const [limitDate, setLimitDate] = useState({
        start_date:'1990-01-01',
        end_date:'2024-01-02',
    })

    useEffect(()=>{
        amg()
        props.dispatch(getRequests())
        props.dispatch(getSettings())
        props.dispatch(checkDateData()).then(c=>{
            setLimitDate({
                start_date:'1990-01-01',
                end_date:dayjs(c.lastDateData[0]?.dy).add(1,'day')
            })
        })
        // setInterval(() => {
        //     if(reqId===0){
        //         props.dispatch(Check_callback_url(dimReqID)).then(c=>{
        //             console.log(c?.payment_id)
        //             setCallbackStatus(c?.payment_id)
        //         })
        //     }
        // }, 5000);
     
    },[])
 

    const [isActive, setIsActive] = useState(true);

    useEffect(()=>{
        let id;
        if(isActive && reqId>0){
            id =  setInterval(() => {
                props.dispatch(Check_callback_url(reqId)).then(c=>{
                    if(c?.payment_id>0){
                        setIsActive(false)
                    }
                    setCallbackStatus(c?.payment_id)
                })
            }, 4000);
        }
        return () => clearInterval(id);
    },[isActive, reqId])

    const onSendFile=(val)=>{
        if(checkPay?.checkPayment?.count>0){
            let request = checkPay.request
            props.dispatch(sendToMail({
                fileType:request.fileType,
                id:request.id
            })).then(c=>{
                if (c?.sendStatus){
                    message.success("Амжилттай илгээлээ.",5)
                }else{
                    message.warning("Системийн алдаа гарлаа.",5)
                }
            })
        }
        if(val?.id>0){
            let request = val
            props.dispatch(sendToMail({
                fileType:request.fileType,
                id:request.id
            })).then(c=>{
                if (c?.sendStatus){
                    message.success("Амжилттай илгээлээ.",5)
                }else{
                    message.warning("Системийн алдаа гарлаа.",5)
                }
            })
        }
    }
    const onDownloadFile=(val)=>{
        if(checkPay?.checkPayment?.count>0){
            let request = checkPay.request 
            props.dispatch(downToMail(request)).then(res=>{
                FileDownload(res,`${request.id}.${request.fileType}`)
            })
        }
        if(val?.id>0){
            let request = val
            props.dispatch(downToMail(request)).then(res=>{
                FileDownload(res,`${request.id}.${request.fileType}`)
            })
        }
    }
    const steps = [
        {
          title: 'Мэдээлэл оруулах',
          content: <div className="flex md:min-h-96 flex-col items-center justify-center">
                    <Form.Item
                        name="element"
                        label="Элемент сонгох"
                        className='2xl:w-6/12 w-full m-0 p-0 mb-6'
                        rules={[
                            {
                            required: true,
                            message: '* Элемент сонгоно уу',
                            }
                        ]}
                        >
                        <Select
                            mode="multiple"
                            allowClear
                            className='w-full text-sm outline-none rounded-md focus:ring-2 m-0 p-0 cursor-pointer'
                            placeholder="Элемент сонгох"
                            options={options}
                            // onChange={(e)=>onChangeHandler(e, 'element')}
                        />
                    </Form.Item>
                    <Form.Item
                        name="timeType"
                        label="Хугацааны төрөл сонгох"
                        className='2xl:w-6/12 w-full m-0 p-0 mb-6'
                        rules={[
                            {
                            required: true,
                            message: 'Хугацааны төрөл сонгох',
                            }
                        ]}
                        >
                        <Select
                            allowClear
                            className='w-full text-sm outline-none rounded-md focus:ring-2 cursor-pointer'
                            placeholder="Хугацааны төрөл сонгох"
                            onChange={(e)=>changeTimeType(e)}
                            options={options_time}
                        />
                    </Form.Item>
                    <Form.Item
                        name="stations"
                        label="Сумын нэр сонгох"
                        className='2xl:w-6/12 w-full m-0 p-0 mb-6'
                        rules={[
                            {
                            required: true,
                            message: 'Станцын индекс сонгох',
                            }
                        ]}
                        >
                        <Select
                            mode="multiple"
                            allowClear
                            className='w-full text-sm outline-none rounded-md focus:ring-2 cursor-pointer'
                            placeholder="Сумын нэр [Станцын индекс] сонгох"
                            optionFilterProp="children"
                            value={selValue}
                            onChange={onChange}
                            onSearch={onSearch}
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                            // options={aimags}
                        >
                            {
                                (aimags || [].length>0) ?
                                    (aimags || []).map((el,i)=>
                                        <Option key={i} value={el.station_id}>{`${el.aimag}, ${el.sum} [${el.station_id}]`}</Option>
                                        // {
                                        //     label:`${el.aimag}, ${el.sum} [${el.station_id}]`,
                                        //     value:el.station_id
                                        // }, 
                                    ) 
                                : null
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="start_date"
                        label="Эхлэх хугацаа сонгох"
                        className='2xl:w-6/12 w-full m-0 p-0 mb-6'
                        rules={[
                            {
                            required: true,
                            message: 'Эхлэх хугацаа сонгох',
                            }
                        ]}
                        >
                        <DatePicker className='w-full outline-none rounded-md focus:ring-2' disabledDate={d => !d || d.isBefore(dayjs(limitDate.start_date,dateFormat)) || d.isAfter(dayjs(limitDate.end_date,dateFormat))} picker={dateType} initialValues={dayjs(new Date(),dateFormat)} format={dateFormat} />
                    </Form.Item>
                    <Form.Item
                        name="end_date"
                        label="Дууссах хугацаа сонгох"
                        className='2xl:w-6/12 w-full m-0 p-0 mb-6'
                        rules={[
                            {
                            required: true,
                            message: 'Дууссах хугацаа сонгох',
                            }
                        ]}
                        >
                        <DatePicker className='w-full outline-none rounded-md focus:ring-2' disabledDate={d => !d || d.isBefore(dayjs(limitDate.start_date,dateFormat)) || d.isAfter(dayjs(limitDate.end_date,dateFormat))} picker={dateType} initialValues={dayjs(new Date(),dateFormat)} format={dateFormat} />
                    </Form.Item>
                    <Form.Item
                        name="fileType"
                        label="Гаралтын файл"
                        className='2xl:w-6/12 w-full m-0 p-0 mb-6'
                        rules={[
                            {
                            required: true,
                            message: 'Гаралтын файлын төрөл сонгох',
                            }
                        ]}
                        >
                        <Select
                            allowClear
                            className='w-full md:w-96 text-sm outline-none rounded-md focus:ring-2 cursor-pointer'
                            placeholder="Гаралтын файлын төрөл сонгох"
                            // defaultValue={['a10', 'c12']}
                            // onChange={handleChange}
                            options={options_type}
                        />
                    </Form.Item>
                    {/* <Form.Item
                        label="Төлөх мөнгөн дүн"
                        className='2xl:w-6/12 w-full m-0 p-0 mb-6'
                        >
                        <InputNumber
                            disabled
                            className='w-full text-sm outline-none rounded-md focus:ring-2 cursor-pointer'
                            value={amount}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        />
                    </Form.Item> */}
          </div>,
        },
        {
            title: 'Төлбөр төлөх суваг сонгох',
            content: <>
                   <div className='w-full bg-white rounded-md h-auto sm:h-96 p-2 flex items-center justify-center text-slate-500'>
                    <div>
                        <div className="flex justify-center text-lg mb-2 text-red-600 w-full">
                                <p className="mr-1">Төлөх мөнгөн дүн: </p>
                                <p className="font-bold">{amount}₮</p>
                        </div>
                        <Button onClick={createPayment} className='text-center flex flex-col sm:flex-row h-auto justify-start items-center border boder-slate-500 p-4 rounded-xl hover:bg-slate-100 cursor-pointer focus:border-blue-500'>
                            <img src={QpayLogo} className="w-16 sm:mr-2" alt="qpay_logo"/>
                            <div className="text-center sm:text-left">
                                <h5 className="font-bold">QPay-ээр төлөх</h5>
                                <p className="text-sm hidden sm:flex">Qpay ашиглан QR код уншуулан төлбөр төлөх</p>
                            </div>
                        </Button>
                    </div>
                  </div>
            </>,
          },
        {
          title: 'Төлбөр төлөх',
          content: <>
                <Spin spinning={createInvo=={} ? true : false}>
                    <div className='w-full bg-white rounded-md md:h-96 p-2 flex flex-col items-center justify-center text-slate-500 text-sm'>
                        <div className='flex flex-col items-center justify-start 2xl:w-7/12 w-full mt-2'>
                            <p className='my-4'><span className='text-blue-500'>* </span>Анхаар: Төлбөр төлсөний дараа Баталгаажуулах товч гарч ирнэ.</p> 
                                {
                                    createInvo!={} ?
                                    // <QR text ={pay.createInvoice.qr_text}/>
                                    <QRCode 
                                        size={200} 
                                        // iconSize={200 / 8} 
                                        value={createInvo?.createInvoice?.qr_text} 
                                        // icon={QpayLogo}
                                    />
                                    : null
                                }
                            <div className='text-center'>
                                {
                                    checkPay!=="" ?
                                        <p className={`my-4 ${ checkPay.checkPayment?.count>0? 'text-green-600' : 'text-red-600'} `}><span className={`${ checkPay.checkPayment?.count>0? 'text-green-600' : 'text-red-600'} `}>* </span>{
                                            checkPay.checkPayment?.count>0? 'Төлбөр амжилттай төлөгдлөө' : 'Төлбөр төлөгдөөгүй байна.'
                                        }</p>
                                    :null
                                }
                                {
                                    callbackStatus>0 ?
                                    <>
                                    <p className='hidden md:flex my-4'><span className='text-red-500'>* </span>Төлбөр баталгаажсан эсэхийг заавал шалгана уу.</p> 
                                    <Button loading={pay.checkPayLoading} onClick={checkPaymentClick} type='primary' className='mb-4 border-none bg-blue-500 text-white rounded-md hover:bg-blue-400 duration-200'>Төлбөр баталгаажуулах</Button>
                                    </>
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                </Spin>
          </>,
        },
        {
          title: 'Дууслаа',
          content: <>
             <div className='w-full bg-white rounded-md md:h-96 p-2 flex flex-col items-center justify-center text-slate-500 text-sm'>
                <div className='flex flex-col items-center justify-end 2xl:w-7/12 w-full mt-2'>
                    <p className='text-blue-500 mb-4'>Имайл хаягруу илгээх болон татаж авах боломжтой</p>
                    <Button onClick={()=>{onDownloadFile()}} className='border-1 border-blue-500 bg-blue-600 text-white rounded-md w-full md:w-96 hover:bg-white hover:text-blue-500 duration-300 flex justify-center items-center' icon={<DownloadOutlined />} >Татаж авах</Button>
                    <Button loading={obs?.sending} onClick={()=>{onSendFile()}} className='border-1 border-blue-500 bg-blue-600 text-white rounded-md w-full md:w-96 hover:bg-white hover:text-blue-500 duration-300 flex justify-center items-center mt-2' icon={<SendOutlined />} >Цахим хаяг руу илгээх</Button>
                </div>
            </div>
          </>,
        },
      ];
    const [current, setCurrent] = useState(0);
    const [continBtn, setContinBtn] = useState(true);
    const next = () => {
        setCurrent(current + 1)
        if(current +1>0){
            setContinBtn(false)
        }
    };
    const prev = () => {
        setCurrent(current - 1);
        if(current - 1===0){
            setContinBtn(true)
        }
    };
    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));


    const convertName=(el)=>{
        if(el=="tt_max") return "Агаарын хамгийн их температур(°C)"
        if(el=="tt_min") return "Агаарын хамгийн бага температур(°C)"
        if(el=="tt_ave") return "Агаарын дундаж температур(°C)"
        if(el=="wind_ave") return "Салхины дундаж хурд(м/с)"
        if(el=="wind_max") return "Салхины хамгийн их хурд(м/с)"
        if(el=="soil_tmax") return "Хөрсний хамгийн их температур(°C)"
        if(el=="soil_tmin") return "Хөрсний хамгийн бага температур(°C)"
        if(el=="soil_tave") return"Хөрсний дундаж температур(°C)"
        if(el=="humd_ave") return "Харьцангуй чийгийн дундаж(%)"
        if(el=="humd_min") return "Харьцангуй чийгийн хамгийн бага(%)"
        if(el=="spres") return "Станцын түвшин дэх агаарын даралт(гПа)"
        if(el=="rr_sum") return "Хур тунадасны нийлбэр(mm)"
    }

    const columns = [
        {
            title: '№',
            key: 'number',
            render:(item, record, index)=>(<>{index+1}</>)
          },
        {
          title: 'Элемент',
          dataIndex: 'elements',
          key: 'elements',
          render: (record) => 
          (
            (record || []).split(',').length ===1 ? convertName(record) : `${convertName((record || []).split(',')[0])}..`                       
          )
        },
        {
          title: 'Хугацааны төрөл',
          dataIndex: 'dateType',
          key: 'dateType',
          responsive: ['lg'],
          render: (text) => <>{
            text==='year' ? 'Жил' :
             text==='month' ? 'Сар' : 'Өдөр'
          }</>,
        },
        {
            title: 'Индекс',
            dataIndex: 'sums',
            key: 'sums',
            responsive: ['sm'],
            render: (record) => 
            (
              (record || []).split(',').length ===1 ? record : `${(record || []).split(',')[0]}..`                       
            )
        },
        {
          title: 'Төлбөрийн төлөв',
          key: 'pay_status',
          dataIndex: 'pay_status',
          responsive: ['lg'],
          filters: [
            {
              text: 'Төлөгдөөгүй',
              value: 'pending',
            },
            {
              text: 'Төлсөн',
              value: 'paid',
            }],
            filterSearch: true,
        onFilter: (value, record) => record.pay_status.includes(value),
          render: (_, { pay_status }) => (
            <>
            {
                (
                    <Tag color={pay_status==="pending"? "red":'green'} key={pay_status}>
                      {pay_status==="pending"? "Төлөгдөөгүй":'Төлсөн'}
                    </Tag>
                  )
            }
            </>
          ),
        },
        {
            title: 'Төлөрийн дүн',
            dataIndex: 'pay_amount',
            key: 'pay_amount',
            render: (value) => <>{
              value
            }₮</>,
            responsive: ['lg'],
          },
        {
            title: 'Эхлэх хугацаа',
            dataIndex: 'start_date',
            key: 'start_date',
            responsive: ['md'],
        },
        {
            title: 'Дууссах хугацаа',
            dataIndex: 'end_date',
            key: 'end_date',
            responsive: ['md'],
        },
        {
            title: 'Үүсгэсэн хугацаа',
            dataIndex: 'created',
            key: 'created',
            responsive: ['xl'],
            render: (date) => <>{dayjs(date).add(-8,'hours').format("YYYY-MM-DD HH:mm")}</>,
        },
        {
          title: 'Үйлдэл',
          key: 'action',
        //   responsive: ['sm'],
          render: (record) => (
            <Space direction="horizontal"  size="small">
                {
                    record?.pay_status==='paid' ?
                    <>
                        <Popover  title="Татаж авах">
                            <Button onClick={()=>onDownloadFile(record)} shape="circle" type="primary" className='border-none bg-blue-500 text-white rounded-md w-full hover:bg-blue-300 hover:text-white duration-300 flex justify-center items-center' icon={<DownloadOutlined />} ></Button>
                        </Popover>
                        <Popover  title="Цахим хаяг руу илгээх">
                            <Button loading={obs?.sending} onClick={()=>{onSendFile(record)}} shape="circle" className='border-none bg-green-500 text-white rounded-md w-full hover:bg-green-400 hover:text-white duration-300 flex justify-center items-center' icon={<SendOutlined />}></Button>
                        </Popover>
                    </>
                : null
                    // <Button onClick={()=>backCallInovice(record)} className='border-none bg-green-500 text-white rounded-md w-full hover:bg-green-400 duration-300 flex justify-center items-center'>Төлбөр төлөх</Button>
                }

            </Space>
          ),
        },
      ];
    
    return(
        <>
        {
            props.selected==="request" ?
            <Form
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 16,
            }}
            name="complex-form"
            className='w-full text-slate-500 text-sm  h-96'
            onFinish={onFinish}
        >
                {/* <QRCode 
                                        size={400} 
                                        // iconSize={200 / 8} 
                                        value={"https://docs.google.com/document/d/1Kw-p4FXgSXLPGJBCBC3K5YArAn2dkQ2h/edit?fbclid=IwAR00rZOQpS7sRk7A7AU6Y9OBieQz85fduQ4fGfO3Ujl7FSlC2zgmjRpV1KQ"} 
                                        // icon={QpayLogo}
                                    /> */}
            
        <div className='flex flex-col justify-betwseen w-full bg-white w-full p-4 rounded-md'>
            <Steps current={current} items={items} />
                <div className="mt-4">{steps[current].content}</div>
                <Form.Item >
                    <div className="flex justify-between items-center sm:justify-start">
                        {
                            continBtn ?
                        current < steps.length - 1 && (
                            //loading={obs.loading} 
                            <Button loading={obs?.loading || false} htmlType="submit" className='bg-blue-500 border-1 border-blue-500 text-white hover:bg-white hover:text-blue-500'
                            >
                                Үргэлжлүүлэх
                            </Button>
                        )
                    : null}
                        {current > 0 && (
                            <Button
                            className='bg-white border-1 border-blue-500  mx-2 text-blue-500 hover:bg-blue-500 hover:text-white'
                            onClick={() => prev()}
                            >
                            Өмнөх
                            </Button>
                        )}
                    </div>
            </Form.Item>
        </div>
        <div className="mt-4">
        Төлбөр тооцоолох тухай Архивын ерөнхий газрын даргын 2021 оны 3 дугаар сарын 12-ны өдрийн А/24 тоот тушаалын 3 дугаар хавсралт, 
        <Link target="_blank" className="text-blue-500 font-bold mt-4" to="https://old.legalinfo.mn/annex?lawid=16170"> тушаалын хуулбар</Link>
        </div>
        
        </Form>

        :

        <Table 
            className="w-full h-36" 
            columns={columns} 
            dataSource={obs.requests || []} 
            pagination={{
                pageSize: 10,
            }}
            expandable={{
                expandedRowRender: (record) => (
                  <Space
                  direction="vertical"
                  size="small"
                  className="w-auto"
                  
                >
                  <div>Элемент:{
                    
                    (record.elements || []).split(',').length ===1 ? 
                        convertName(record.elements) 
                    : ((record.elements || []).split(',')).map(el=>{
                        return `${convertName(el) }, `
                    }) 
                  }</div>
                  <div>Хугацааны төрөл:{record.dateType}</div>
                  <div>Индекс:{record.sums}</div>
                  <div>Мэдээний Хугацаа:{record.start_date}-{record.end_date}</div>
                  <div>Төлбөр:
                    <Tag color={record.pay_status==="pending"? "red":'green'} key={record.pay_status}>
                      {record.pay_status==="pending"? "Төлөгдөөгүй":'Төлсөн'}
                    </Tag>
                  </div>
                  <div>Төлбөр:{record.pay_amount}₮</div>
                  <Space direction="horizontal"  size="small">
                {
                    record?.pay_status==='paid' ?
                    <>
                       <Popover  title="Татаж авах">
                            <Button onClick={()=>onDownloadFile(record)} shape="circle" type="primary" className='border-none bg-blue-500 text-white rounded-md w-full hover:bg-blue-300 hover:text-white duration-300 flex justify-center items-center' icon={<DownloadOutlined />} ></Button>
                        </Popover>
                        <Popover  title="Цахим хаяг руу илгээх">
                            <Button loading={obs?.sending} onClick={()=>{onSendFile(record)}} shape="circle" className='border-none bg-green-500 text-white rounded-md w-full hover:bg-green-400 hover:text-white duration-300 flex justify-center items-center' icon={<SendOutlined />}></Button>
                        </Popover>
                    </>
                : null
                    // <Button onClick={()=>backCallInovice(record)} className='border-none bg-green-500 text-white rounded-md w-full hover:bg-green-400 duration-300 flex justify-center items-center'>Төлбөр төлөх</Button>
                }

            </Space>
                  </Space>
                  
                ),
                // rowExpandable: (record) => 
                // (
                //   (record.sums).split(',').length !==1                          
                // )
              }}
        />

        }
      </>
    )
}

export default connect(reducer)(Card);