import Dashboard from "./components/dashboard/Dashboard";
import Pages from "./components/others/Page";
import { useRoutes, Navigate } from 'react-router-dom';
import Sign from "./components/login/Sign"
import Signup from "./components/login/Signup";
import Reset from "./components/login/Reset";
import DashboardAdmin from "./admin/Dashboard"
import React, { useEffect, useState } from 'react';
import { getLogin } from './redux/actions/userAction';
import axios from "axios";
import { useDispatch } from 'react-redux'

function App() {
  // const [isAuthenticated, setIsAuthenticated] = useState("");

  // useEffect(()=>{
  //   const fetchData = async () => {
  //     const res = await axios.get(`http://localhost:8090/api/user/getlogin`, {withCredentials: true})
  //     if(res.data?.loggedIn){
  //       setIsAuthenticated(res.data)
  //     }else{
  //       setIsAuthenticated("")
  //     }
  //   };
  //   fetchData();
  // },[])

  const routes = useRoutes([
      { path: '/', element: <Dashboard /> },
      { path: '/request', element: <Pages selected="request"/> },
      { path: '/services', element:<Pages selected="services"/> },
      { path: '/login', element: <Sign /> },
      { path: '/register', element: <Signup /> },
      { path: '/reset-password', element: <Reset /> },
      { path: '/admin/requests', element:  <DashboardAdmin selected="requests"  /> },
      { path: '/admin/users', element: <DashboardAdmin selected="users"  /> },
      { path: '/admin/settings', element: <DashboardAdmin selected="settings"  /> },
      { path: '/admin/paylists', element: <DashboardAdmin selected="paylists"  /> },
      { path: '/admin/dashboard', element: <DashboardAdmin selected="dashboard"  /> },
      { path: '/admin', element: <DashboardAdmin selected="dashboard"  /> },
  ]);

  return routes;
}

export default App;
