 import { combineReducers } from "redux";
import user from './user';
import obs from './obs';
import payment from './pay';
export default combineReducers({
    user:user,
    pay:payment,
    obs:obs
 });

// const reducers=combineReducers({
//     aimagReducer:aimagReducer,
// })
// export default reducers

