import React, { useState, useEffect, useMemo, useMapEvent, useCallback, MouseEvent  }  from 'react';
import { MapContainer, TileLayer, Marker, Popup, Tooltip, Circle, CircleMarker, GeoJSON } from 'react-leaflet'
import { Modal, Tag, Select, Space, Row, Col } from "antd"
import dayjs from 'dayjs';
import { DingdingOutlined } from '@ant-design/icons';
import Mongolian_map from "./Mongolian_map.json"
import Population from "./mn_population.json"
const data =[
    {
      "station_id": 48299900,
      "syn_id": 44229,
      "aimag": "Архангай",
      "sum": "Тариат",
      "station_name": "Тариат",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "12/1/1969 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01482999001Тариат",
      "presona_code": "4829990",
      "lat": 48.15708,
      "lon": 99.87585,
      "hhh": "2055"
    },
    {
      "station_id": 48501400,
      "syn_id": 44237,
      "aimag": "Архангай",
      "sum": "Эрдэнэмандал",
      "station_name": "Эрдэнэмандал",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1964 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01485014101ЭРДЭНЭМАНДАЛ",
      "presona_code": "4850141",
      "lat": 48.53,
      "lon": 101.38,
      "hhh": "1509.5"
    },
    {
      "station_id": 47501900,
      "syn_id": 44281,
      "aimag": "Архангай",
      "sum": "Төвшрүүлэх",
      "station_name": "Төвшрүүлэх",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1995 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01475019101ТӨВШРYYЛЭХ",
      "presona_code": "4750191",
      "lat": 47.3869171,
      "lon": 101.908585,
      "hhh": "1541.5"
    },
    {
      "station_id": 47401500,
      "syn_id": 44282,
      "aimag": "Архангай",
      "sum": "Эрдэнэбулган",
      "station_name": "Цэцэрлэг",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1937 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01474015101Цэцэрлэг",
      "presona_code": "4740151",
      "lat": 47.4714127,
      "lon": 101.462929,
      "hhh": "1693"
    },
    {
      "station_id": 48891200,
      "syn_id": 44200,
      "aimag": "Баян-Өлгий",
      "sum": "Баяннуур",
      "station_name": "Баяннуур",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "9/1/1995 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01488912001БАЯННУУР",
      "presona_code": "4889120",
      "lat": 48.9394875,
      "lon": 91.16165,
      "hhh": "1351.6"
    },
    {
      "station_id": 49690400,
      "syn_id": 44210,
      "aimag": "Баян-Өлгий",
      "sum": "Ногооннуур",
      "station_name": "Ногооннуур",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "11/1/1984 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01496904001НОГООННУУР",
      "presona_code": "4969040",
      "lat": 49.6141853,
      "lon": 90.2483444,
      "hhh": "1351.6"
    },
    {
      "station_id": 49090000,
      "syn_id": 44214,
      "aimag": "Баян-Өлгий",
      "sum": "Өлгий",
      "station_name": "Өлгий",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "12/1/1958 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01490900001ӨЛГИЙ",
      "presona_code": "4909000",
      "lat": 48.98003,
      "lon": 89.93844,
      "hhh": "1715.2"
    },
    {
      "station_id": 48389500,
      "syn_id": 44217,
      "aimag": "Баян-Өлгий",
      "sum": "Алтай",
      "station_name": "Ялалт",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1969 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01483895001ЯЛАЛТ",
      "presona_code": "4838950",
      "lat": 48.2991447,
      "lon": 89.51517,
      "hhh": "2148.2"
    },
    {
      "station_id": 47890800,
      "syn_id": 44261,
      "aimag": "Баян-Өлгий",
      "sum": "Дэлүүн",
      "station_name": "Дэлүүн",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1987 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01478908001ДЭЛYYН",
      "presona_code": "4789080",
      "lat": 47.86258,
      "lon": 90.69703,
      "hhh": "2160"
    },
    {
      "station_id": 46991100,
      "syn_id": 44263,
      "aimag": "Баян-Өлгий",
      "sum": "Булган",
      "station_name": "Дөчинжил",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1977 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01469911001ДӨЧИНЖИЛ",
      "presona_code": "4699110",
      "lat": 46.93016,
      "lon": 91.08183,
      "hhh": "1941.8"
    },
    {
      "station_id": 46898100,
      "syn_id": 44275,
      "aimag": "Баянхонгор",
      "sum": "Баянбулаг",
      "station_name": "Баянбулаг",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "11/1/1974 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01468981001БАЯНБУЛАГ",
      "presona_code": "4689810",
      "lat": 46.81211,
      "lon": 98.08706,
      "hhh": "2255.8"
    },
    {
      "station_id": 47299600,
      "syn_id": 44276,
      "aimag": "Баянхонгор",
      "sum": "Жаргалант",
      "station_name": "Байдраг",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "11/1/1991 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01472996001БАЙДРАГ",
      "presona_code": "4729960",
      "lat": 47.01647,
      "lon": 99.47955,
      "hhh": "2034"
    },
    {
      "station_id": 46700100,
      "syn_id": 44284,
      "aimag": "Баянхонгор",
      "sum": "Галуут",
      "station_name": "Галуут",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "7/1/1956 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01467001101ГАЛУУТ",
      "presona_code": "4670011",
      "lat": 46.7020836,
      "lon": 100.142807,
      "hhh": "2125"
    },
    {
      "station_id": 46100700,
      "syn_id": 44287,
      "aimag": "Баянхонгор",
      "sum": "Баянхонгор",
      "station_name": "Баянхонгор",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "11/1/1962 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01461007101БАЯНХОНГОР",
      "presona_code": "4610071",
      "lat": 46.17375,
      "lon": 100.709862,
      "hhh": "1859"
    },
    {
      "station_id": 45098900,
      "syn_id": 44326,
      "aimag": "Баянхонгор",
      "sum": "Баянцагаан",
      "station_name": "Баянцагаан",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "12/1/2008 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01450989001БАЯНЦАГААН",
      "presona_code": "4509890",
      "lat": 45.0124435,
      "lon": 98.9332,
      "hhh": null
    },
    {
      "station_id": 44599300,
      "syn_id": 44329,
      "aimag": "Баянхонгор",
      "sum": "Жинст",
      "station_name": "Жинст",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "12/1/1972 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01445993001ЖИНСТ",
      "presona_code": "4459930",
      "lat": 44.54536,
      "lon": 99.27247,
      "hhh": "2219"
    },
    {
      "station_id": 45200800,
      "syn_id": 44334,
      "aimag": "Баянхонгор",
      "sum": "Богд",
      "station_name": "Хориулт",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1994 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01452008101ХОРИУЛТ",
      "presona_code": "4520081",
      "lat": 45.1943626,
      "lon": 100.777084,
      "hhh": "1280"
    },
    {
      "station_id": 44800300,
      "syn_id": 44337,
      "aimag": "Баянхонгор",
      "sum": "Баянговь",
      "station_name": "Баянговь",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1975 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": "2013 оны 10 сараас станц болсон",
      "persona_register": "01448003101БАЯНГОВЬ",
      "presona_code": "4480031",
      "lat": 44.74811,
      "lon": 100.39933,
      "hhh": null
    },
    {
      "station_id": 43299000,
      "syn_id": 44366,
      "aimag": "Баянхонгор",
      "sum": "Жинст",
      "station_name": "Эхийнгол",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1979 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01432990001ЭХИЙНГОЛ",
      "presona_code": "4329900",
      "lat": 43.244133,
      "lon": 99.0075455,
      "hhh": "960"
    },
    {
      "station_id": 50002600,
      "syn_id": 44205,
      "aimag": "Булган",
      "sum": "Тэшиг",
      "station_name": "Тэшиг",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "7/1/2000 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "02500026101ТЭШИГ",
      "presona_code": "5000261",
      "lat": 49.9355049,
      "lon": 102.667076,
      "hhh": "1022"
    },
    {
      "station_id": 49402700,
      "syn_id": 44232,
      "aimag": "Булган",
      "sum": "Хутаг-єндєр",
      "station_name": "Хутаг",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "4/1/1961 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01494027101ХУТАГ",
      "presona_code": "4940271",
      "lat": 49.3937035,
      "lon": 102.709839,
      "hhh": "933.3"
    },
    {
      "station_id": 49404000,
      "syn_id": 44233,
      "aimag": "Булган",
      "sum": "Сэлэнгэ",
      "station_name": "Ингэттолгой",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "9/1/1991 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01494040101ИНГЭТТОЛГОЙ",
      "presona_code": "4940401",
      "lat": 49.4556122,
      "lon": 103.977478,
      "hhh": "800"
    },
    {
      "station_id": 48303000,
      "syn_id": 44235,
      "aimag": "Булган",
      "sum": "Могод",
      "station_name": "Могод",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1991 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01483030101МОГОД",
      "presona_code": "4830301",
      "lat": 48.2691154,
      "lon": 102.969017,
      "hhh": "1600"
    },
    {
      "station_id": 48803600,
      "syn_id": 44239,
      "aimag": "Булган",
      "sum": "Булган",
      "station_name": "Булган",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1941 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01488036101БУЛГАН",
      "presona_code": "4880361",
      "lat": 48.81822,
      "lon": 103.5189,
      "hhh": "1209.6"
    },
    {
      "station_id": 47803500,
      "syn_id": 44283,
      "aimag": "Булган",
      "sum": "Гурванбулаг",
      "station_name": "Гурванбулаг",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1982 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01478035101ГУРВАНБУЛАГ",
      "presona_code": "4780351",
      "lat": 47.7445,
      "lon": 103.481277,
      "hhh": "1096.6"
    },
    {
      "station_id": 47494600,
      "syn_id": 44262,
      "aimag": "Говь-Алтай",
      "sum": "Хөхморьт",
      "station_name": "Хөхморьт",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "11/1/1991 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01474946001ХӨХМОРЬТ",
      "presona_code": "4749460",
      "lat": 47.355835,
      "lon": 94.523056,
      "hhh": "1460"
    },
    {
      "station_id": 46393900,
      "syn_id": 44266,
      "aimag": "Говь-Алтай",
      "sum": "Тонхил",
      "station_name": "Тонхил",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "11/1/1972 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01463939001ТОНХИЛ",
      "presona_code": "4639390",
      "lat": 46.30361,
      "lon": 93.91055,
      "hhh": "2221"
    },
    {
      "station_id": 45494400,
      "syn_id": 44268,
      "aimag": "Говь-Алтай",
      "sum": "Бугат",
      "station_name": "Бугат",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1987 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01454944001БУГАТ",
      "presona_code": "4549440",
      "lat": 45.5601158,
      "lon": 94.3616943,
      "hhh": "2211"
    },
    {
      "station_id": 46496200,
      "syn_id": 44277,
      "aimag": "Говь-Алтай",
      "sum": "Есөн булаг",
      "station_name": "Алтай",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "11/1/1954 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01464962001АЛТАЙ",
      "presona_code": "4649620",
      "lat": 46.37754,
      "lon": 96.2381,
      "hhh": "1200"
    },
    {
      "station_id": 44995500,
      "syn_id": 44324,
      "aimag": "Говь-Алтай",
      "sum": "Алтай",
      "station_name": "Ажбогд",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1984 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01449955001АЖБОГД",
      "presona_code": "4499550",
      "lat": 44.61923,
      "lon": 94.92341,
      "hhh": "2182"
    },
    {
      "station_id": 44996800,
      "syn_id": 44325,
      "aimag": "Говь-Алтай",
      "sum": "Цогт",
      "station_name": "Тоорой",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "9/1/1959 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01449968001ТООРОЙ",
      "presona_code": "4499680",
      "lat": 44.9242973,
      "lon": 96.7536545,
      "hhh": "2300"
    },
    {
      "station_id": 46408200,
      "syn_id": 44298,
      "aimag": "Говьсүмбэр",
      "sum": "Чойр",
      "station_name": "Чойр",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "7/1/1941 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01464082101ЧОЙР",
      "presona_code": "4640821",
      "lat": 46.3500366,
      "lon": 108.3744,
      "hhh": "1286"
    },
    {
      "station_id": 49505900,
      "syn_id": 44244,
      "aimag": "Дархан-Уул",
      "sum": "Дархан",
      "station_name": "Дархан",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1984 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01495059101ДАРХАН",
      "presona_code": "4950591",
      "lat": 49.4905,
      "lon": 105.939667,
      "hhh": "707"
    },
    {
      "station_id": 49806500,
      "syn_id": 44251,
      "aimag": "Дархан-Уул",
      "sum": "Шарын гол",
      "station_name": "Шаазгайт",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "6/1/2009 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01498065101ШААЗГАЙТ",
      "presona_code": "4980651",
      "lat": 49.2450562,
      "lon": 106.411331,
      "hhh": null
    },
    {
      "station_id": 44408800,
      "syn_id": 44348,
      "aimag": "Дорноговь",
      "sum": "Мандах",
      "station_name": "Мандах",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "11/1/1973 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01444088101МАНДАХ",
      "presona_code": "4440881",
      "lat": 44.4014931,
      "lon": 108.249458,
      "hhh": "1318"
    },
    {
      "station_id": 45811200,
      "syn_id": 44351,
      "aimag": "Дорноговь",
      "sum": "Дэлгэрэх",
      "station_name": "Дэлгэрэх",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "11/1/1998 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01458112101ДЭЛГЭРЭХ",
      "presona_code": "4581121",
      "lat": 45.7954445,
      "lon": 111.210983,
      "hhh": "1062"
    },
    {
      "station_id": 44910100,
      "syn_id": 44354,
      "aimag": "Дорноговь",
      "sum": "Сайншанд",
      "station_name": "Сайншанд",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "8/1/1938 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01449101101САЙНШАНД",
      "presona_code": "4491011",
      "lat": 44.87809,
      "lon": 110.119286,
      "hhh": "936"
    },
    {
      "station_id": 44411100,
      "syn_id": 44357,
      "aimag": "Дорноговь",
      "sum": "Улаан-Уул",
      "station_name": "Улаан-Уул",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1971 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": "2013 оны 5 сараас станц болсон",
      "persona_register": "01444111101УЛААН-УУЛ",
      "presona_code": "4441111",
      "lat": 44.44361,
      "lon": 111.086113,
      "hhh": null
    },
    {
      "station_id": 43711900,
      "syn_id": 44358,
      "aimag": "Дорноговь",
      "sum": "Замын-Үүд",
      "station_name": "Замын-Үүд",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "11/1/1955 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01437119101ЗАМЫН-YYД",
      "presona_code": "4371191",
      "lat": 43.7138977,
      "lon": 111.904221,
      "hhh": "960.7"
    },
    {
      "station_id": 43609600,
      "syn_id": 44386,
      "aimag": "Дорноговь",
      "sum": "Хөвсгөл",
      "station_name": "Хөвсгөл",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1969 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01436096101ХӨВСГӨЛ",
      "presona_code": "4360961",
      "lat": 43.60462,
      "lon": 109.636139,
      "hhh": "1001"
    },
    {
      "station_id": 49712700,
      "syn_id": 44255,
      "aimag": "Дорнод",
      "sum": "Баян-Уул",
      "station_name": "Онон",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1991 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01497127101ОНОН",
      "presona_code": "4971271",
      "lat": 49.12361,
      "lon": 112.663918,
      "hhh": "895.5"
    },
    {
      "station_id": 49614400,
      "syn_id": 44256,
      "aimag": "Дорнод",
      "sum": "Дашбалбар",
      "station_name": "Дашбалбар",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "12/1/1975 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01496144101ДАШБАЛБАР",
      "presona_code": "4961441",
      "lat": 49.5509148,
      "lon": 114.404495,
      "hhh": "705.2"
    },
    {
      "station_id": 48114600,
      "syn_id": 44259,
      "aimag": "Дорнод",
      "sum": "Чойбалсан",
      "station_name": "Чойбалсан",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "6/1/1936 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01481146101ЧОЙБАЛСАН",
      "presona_code": "4811461",
      "lat": 48.08143,
      "lon": 114.537842,
      "hhh": "747"
    },
    {
      "station_id": 47618600,
      "syn_id": 44313,
      "aimag": "Дорнод",
      "sum": "Халхгол",
      "station_name": "Халхгол",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "5/1/1961 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01476186101ХАЛХГОЛ",
      "presona_code": "4761861",
      "lat": 47.6300468,
      "lon": 118.622139,
      "hhh": "688"
    },
    {
      "station_id": 47215700,
      "syn_id": 44314,
      "aimag": "Дорнод",
      "sum": "Матад",
      "station_name": "Матад",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "7/1/1975 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01472157101МАТАД",
      "presona_code": "4721571",
      "lat": 47.150013,
      "lon": 115.672386,
      "hhh": "906.7"
    },
    {
      "station_id": 46407600,
      "syn_id": 44300,
      "aimag": "Дундговь",
      "sum": "Цагаандэлгэр",
      "station_name": "Цагаандэлгэр",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/2009 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01464076101Цагаандэлгэр",
      "presona_code": "4640761",
      "lat": 46.40522,
      "lon": 107.637306,
      "hhh": null
    },
    {
      "station_id": 46005000,
      "syn_id": 44333,
      "aimag": "Дундговь",
      "sum": "Эрдэнэдалай",
      "station_name": "Эрдэнэдалай",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "8/1/1992 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01460050101Эрдэнэдалай",
      "presona_code": "4600501",
      "lat": 45.9987526,
      "lon": 104.938164,
      "hhh": "1400"
    },
    {
      "station_id": 45403900,
      "syn_id": 44336,
      "aimag": "Дундговь",
      "sum": "Сайхан-Овоо",
      "station_name": "Сайхан-Овоо",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1967 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01454039101Сайхан-Овоо",
      "presona_code": "4540391",
      "lat": 45.4609451,
      "lon": 103.900154,
      "hhh": "1316"
    },
    {
      "station_id": 45806300,
      "syn_id": 44341,
      "aimag": "Дундговь",
      "sum": "Мандалговь",
      "station_name": "Мандалговь",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "2/1/1944 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01458063101Мандалговь",
      "presona_code": "4580631",
      "lat": 45.74304,
      "lon": 106.264175,
      "hhh": "1393.1"
    },
    {
      "station_id": 46007500,
      "syn_id": 44342,
      "aimag": "Дундговь",
      "sum": "Говь-Угтаал",
      "station_name": "Говь-Угтаал",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1962 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": "2013 оны 10 сараас станц болсон",
      "persona_register": "02460075101Говь-Угтаал",
      "presona_code": "4600751",
      "lat": 46.049,
      "lon": 107.484253,
      "hhh": null
    },
    {
      "station_id": 45407000,
      "syn_id": 44345,
      "aimag": "Дундговь",
      "sum": "Гурвансайхан",
      "station_name": "Гурвансайхан",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "8/1/1984 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01454070101Гурвансайхан",
      "presona_code": "4540701",
      "lat": 45.5282173,
      "lon": 107.04467,
      "hhh": "1196"
    },
    {
      "station_id": 47695000,
      "syn_id": 44219,
      "aimag": "Завхан",
      "sum": "Дөрвөлжин",
      "station_name": "Дөрвөлжин",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "12/1/1962 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01476950001ДӨРВӨЛЖИН",
      "presona_code": "4769500",
      "lat": 47.6465225,
      "lon": 94.9992142,
      "hhh": "1893"
    },
    {
      "station_id": 48996800,
      "syn_id": 44220,
      "aimag": "Завхан",
      "sum": "Нөмрөг",
      "station_name": "Нөмрөг",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "12/1/1992 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01489968001НӨМРӨГ",
      "presona_code": "4899680",
      "lat": 48.87053,
      "lon": 96.961586,
      "hhh": "1846.1"
    },
    {
      "station_id": 49796400,
      "syn_id": 44221,
      "aimag": "Завхан",
      "sum": "Баянтэс",
      "station_name": "Баян-Уул",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1962 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01497964001БАЯН-УУЛ",
      "presona_code": "4979640",
      "lat": 49.7005,
      "lon": 96.3634,
      "hhh": "1420"
    },
    {
      "station_id": 48796800,
      "syn_id": 44224,
      "aimag": "Завхан",
      "sum": "Цэцэн-Уул",
      "station_name": "Цэцэн-Уул",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "10/1/1975 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01487968001ЦЭЦЭН-УУЛ",
      "presona_code": "4879680",
      "lat": 48.748,
      "lon": 96.00425,
      "hhh": "1928"
    },
    {
      "station_id": 48798300,
      "syn_id": 44225,
      "aimag": "Завхан",
      "sum": "Тосонцэнгэл",
      "station_name": "Тосонцэнгэл",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1964 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01487983001ТОСОНЦЭНГЭЛ",
      "presona_code": "4879830",
      "lat": 48.7630539,
      "lon": 98.2662,
      "hhh": "1724.6"
    },
    {
      "station_id": 48395100,
      "syn_id": 44227,
      "aimag": "Завхан",
      "sum": "Завханмандал",
      "station_name": "Завханмандал",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "9/1/2009 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01483951001ЗАВХАНМАНДАЛ",
      "presona_code": "4839510",
      "lat": 48.3251953,
      "lon": 95.09944,
      "hhh": null
    },
    {
      "station_id": 47896800,
      "syn_id": 44272,
      "aimag": "Завхан",
      "sum": "Улиастай",
      "station_name": "Улиастай",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1937 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01478968001УЛИАСТАЙ",
      "presona_code": "4789680",
      "lat": 47.727005,
      "lon": 96.84754,
      "hhh": "1767"
    },
    {
      "station_id": 47292600,
      "syn_id": 44274,
      "aimag": "Завхан",
      "sum": "Отгон",
      "station_name": "Отгон",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/2000 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01472926001ОТГОН",
      "presona_code": "4729260",
      "lat": 47.20961,
      "lon": 97.6047,
      "hhh": "2138"
    },
    {
      "station_id": 49004100,
      "syn_id": 44236,
      "aimag": "Орхон",
      "sum": "Эрдэнэт",
      "station_name": "Эрдэнэт",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "11/1/1972 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01490041101ЭРДЭНЭТ-ОВОО",
      "presona_code": "4900411",
      "lat": 49.0366859,
      "lon": 104.085823,
      "hhh": "1300"
    },
    {
      "station_id": 46902800,
      "syn_id": 44285,
      "aimag": "Өвөрхангай",
      "sum": "Хужирт",
      "station_name": "Хужирт",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "5/1/1944 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01469028101ХУЖИРТ",
      "presona_code": "4690281",
      "lat": 46.9004173,
      "lon": 102.771667,
      "hhh": "1650"
    },
    {
      "station_id": 46302800,
      "syn_id": 44288,
      "aimag": "Өвөрхангай",
      "sum": "Арвайхээр",
      "station_name": "Арвайхээр",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1940 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01463028101АРВАЙХЭЭР",
      "presona_code": "4630281",
      "lat": 46.25906,
      "lon": 102.789345,
      "hhh": "1813"
    },
    {
      "station_id": 44604100,
      "syn_id": 44289,
      "aimag": "Өвөрхангай",
      "sum": "Баян-Өндөр",
      "station_name": "Баян-Өндөр",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "7/1/2008 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "02446041101БАЯН-ӨНДӨР",
      "presona_code": "4460411",
      "lat": 46.4984436,
      "lon": 104.112144,
      "hhh": "1600"
    },
    {
      "station_id": 47202800,
      "syn_id": 44330,
      "aimag": "Өвөрхангай",
      "sum": "Хархорин",
      "station_name": "Хархорин",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1993 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01472028101ХАРХОРИН",
      "presona_code": "4720281",
      "lat": 47.1948738,
      "lon": 102.829567,
      "hhh": "1480"
    },
    {
      "station_id": 45201400,
      "syn_id": 44331,
      "aimag": "Өвөрхангай",
      "sum": "Баруунбаян-Улаан",
      "station_name": "Баруунбаян-Улаан",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "7/1/2008 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01452014101БАРУУНБАЯН-УЛААН",
      "presona_code": "4520141",
      "lat": 45.1760139,
      "lon": 101.420982,
      "hhh": "1260"
    },
    {
      "station_id": 45603000,
      "syn_id": 44332,
      "aimag": "Өвөрхангай",
      "sum": "Төгрөг",
      "station_name": "Төгрөг",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "8/1/1995 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01456030101ТӨГРӨГ",
      "presona_code": "4560301",
      "lat": 45.5391045,
      "lon": 102.99897,
      "hhh": "1390"
    },
    {
      "station_id": 44702200,
      "syn_id": 44338,
      "aimag": "Өвөрхангай",
      "sum": "Богд",
      "station_name": "Богд",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1975 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01447022101БОГД",
      "presona_code": "4470221",
      "lat": 44.67,
      "lon": 102.17,
      "hhh": "1521"
    },
    {
      "station_id": 44103600,
      "syn_id": 44339,
      "aimag": "Өмнөговь",
      "sum": "Булган",
      "station_name": "Сайхан",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "3/1/1961 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01441036101САЙХАН",
      "presona_code": "4410361",
      "lat": 44.0953064,
      "lon": 103.537766,
      "hhh": "1301.6"
    },
    {
      "station_id": 44405300,
      "syn_id": 44347,
      "aimag": "Өмнөговь",
      "sum": "Цогт-Овоо",
      "station_name": "Цогт-Овоо",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "8/1/1962 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01444053101ЦОГТ-ОВОО",
      "presona_code": "4440531",
      "lat": 44.42286,
      "lon": 105.317795,
      "hhh": "1299"
    },
    {
      "station_id": 43604400,
      "syn_id": 44373,
      "aimag": "Өмнөговь",
      "sum": "Даланзадгад",
      "station_name": "Даланзадгад",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "11/1/1936 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01436044101ДАЛАНЗАДГАД",
      "presona_code": "4360441",
      "lat": 43.5663872,
      "lon": 104.43528,
      "hhh": "1469.7"
    },
    {
      "station_id": 43201000,
      "syn_id": 44374,
      "aimag": "Өмнөговь",
      "sum": "Гурвантэс",
      "station_name": "Гурвантэс",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "7/1/1970 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01432010101ГУРВАНТЭС",
      "presona_code": "4320101",
      "lat": 43.2311,
      "lon": 101.0425,
      "hhh": "1725.8"
    },
    {
      "station_id": 44106800,
      "syn_id": 44382,
      "aimag": "Өмнөговь",
      "sum": "Манлай",
      "station_name": "Манлай",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "10/1/1991 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01441068101МАНЛАЙ",
      "presona_code": "4410681",
      "lat": 44.07829,
      "lon": 106.871941,
      "hhh": "1298.32"
    },
    {
      "station_id": 43207200,
      "syn_id": 44385,
      "aimag": "Өмнөговь",
      "sum": "Ханбогд",
      "station_name": "Ханбогд",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "12/1/1975 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01432072101ХАНБОГД",
      "presona_code": "4320721",
      "lat": 43.1957626,
      "lon": 107.193405,
      "hhh": "1114"
    },
    {
      "station_id": 46713300,
      "syn_id": 44305,
      "aimag": "Сүхбаатар",
      "sum": "Баруун-Урт",
      "station_name": "Баруун-Урт",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1960 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01467133101БАРУУН-УРТ",
      "presona_code": "4671331",
      "lat": 46.6712761,
      "lon": 113.284386,
      "hhh": "991"
    },
    {
      "station_id": 45915400,
      "syn_id": 44317,
      "aimag": "Сүхбаатар",
      "sum": "Эрдэнэцагаан",
      "station_name": "Эрдэнэцагаан",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "3/1/1940 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01459154101ЭРДЭНЭЦАГААН",
      "presona_code": "4591541",
      "lat": 45.90228,
      "lon": 115.36264,
      "hhh": "1080"
    },
    {
      "station_id": 45712400,
      "syn_id": 44352,
      "aimag": "Сүхбаатар",
      "sum": "Баяндэлгэр",
      "station_name": "Байшинт/Баяндэлгэр",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1939 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": "1939-1968-??????? 1969-??? ??????????",
      "persona_register": "01457124101БАЯНДЭЛГЭР",
      "presona_code": "4571241",
      "lat": 45.72575,
      "lon": 112.359032,
      "hhh": "1102"
    },
    {
      "station_id": 45313800,
      "syn_id": 44356,
      "aimag": "Сүхбаатар",
      "sum": "Дарьганга",
      "station_name": "Дарьганга",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/2009 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "02453138101ДАРЬГАНГА",
      "presona_code": "4531381",
      "lat": 45.3080559,
      "lon": 113.849808,
      "hhh": null
    },
    {
      "station_id": 50303000,
      "syn_id": 44209,
      "aimag": "Сэлэнгэ",
      "sum": "Цагааннуур",
      "station_name": "Цагааннуур",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "12/1/1991 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01503030101ЦАГААННУУР",
      "presona_code": "5030301",
      "lat": 50.1104431,
      "lon": 105.451859,
      "hhh": "800"
    },
    {
      "station_id": 50206200,
      "syn_id": 44240,
      "aimag": "Сэлэнгэ",
      "sum": "Сүхбаатар",
      "station_name": "Сүхбаатар",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "11/1/1965 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01502062101СYХБААТАР",
      "presona_code": "5020621",
      "lat": 50.2431335,
      "lon": 106.172882,
      "hhh": "632"
    },
    {
      "station_id": 48906100,
      "syn_id": 44241,
      "aimag": "Сэлэнгэ",
      "sum": "Баянгол",
      "station_name": "Баруунхараа",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1940 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01489061101БАРУУНХАРАА",
      "presona_code": "4890611",
      "lat": 48.9131165,
      "lon": 106.089752,
      "hhh": "804"
    },
    {
      "station_id": 49205400,
      "syn_id": 44242,
      "aimag": "Сэлэнгэ",
      "sum": "Орхон",
      "station_name": "Орхон",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "8/1/1968 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01492054101ОРХОН",
      "presona_code": "4920541",
      "lat": 49.1437225,
      "lon": 105.402473,
      "hhh": "754"
    },
    {
      "station_id": 49806700,
      "syn_id": 44243,
      "aimag": "Сэлэнгэ",
      "sum": "Ерөө",
      "station_name": "Ерөө",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "9/1/1961 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01498067101ЕРӨӨ",
      "presona_code": "4980671",
      "lat": 49.7489166,
      "lon": 106.661469,
      "hhh": "676.4"
    },
    {
      "station_id": 48805000,
      "syn_id": 44245,
      "aimag": "Сэлэнгэ",
      "sum": "Орхонтуул",
      "station_name": "Орхонтуул",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "9/1/1992 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01488050101ОРХОНТУУЛ",
      "presona_code": "4880501",
      "lat": 48.83522,
      "lon": 104.806168,
      "hhh": "781.4"
    },
    {
      "station_id": 48906900,
      "syn_id": 44253,
      "aimag": "Сэлэнгэ",
      "sum": "Мандал",
      "station_name": "Зүүнхараа",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1964 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": "1978 он хүртэл  2001 оны 8 сараас",
      "persona_register": "01489069101ЗYYНХАРАА",
      "presona_code": "4890691",
      "lat": 48.8609428,
      "lon": 106.442642,
      "hhh": "870.2"
    },
    {
      "station_id": 48206300,
      "syn_id": 44246,
      "aimag": "Төв",
      "sum": "Баянчандмань",
      "station_name": "Баянчандмань",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "11/1/1989 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01482063101БАЯНЧАНДМАНЬ",
      "presona_code": "4820631",
      "lat": 48.2273331,
      "lon": 106.284584,
      "hhh": "1253"
    },
    {
      "station_id": 48305400,
      "syn_id": 44247,
      "aimag": "Төв",
      "sum": "Угтаалцайдам",
      "station_name": "Угтаал",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "9/1/1979 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01483054101Угтаал",
      "presona_code": "4830541",
      "lat": 48.25811,
      "lon": 105.405281,
      "hhh": "1146"
    },
    {
      "station_id": 48208500,
      "syn_id": 44250,
      "aimag": "Төв",
      "sum": "Мөнгөнморьт",
      "station_name": "Мөнгөнморьт",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/11/1988 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01482085101МӨНГӨНМОРЬТ",
      "presona_code": "4820851",
      "lat": 48.2063065,
      "lon": 108.48658,
      "hhh": "1430"
    },
    {
      "station_id": 48505900,
      "syn_id": 44252,
      "aimag": "Төв",
      "sum": "Жаргалант",
      "station_name": "Жаргалант",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "11/1/1991 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01485059101ЖАРГАЛАНТ",
      "presona_code": "4850591",
      "lat": 48.5265,
      "lon": 105.880859,
      "hhh": "1008"
    },
    {
      "station_id": 47204200,
      "syn_id": 44286,
      "aimag": "Төв",
      "sum": "Эрдэнэсант",
      "station_name": "Эрдэнэсант",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "2/1/1962 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01472042101Эрдэнэсант",
      "presona_code": "4720421",
      "lat": 47.33339,
      "lon": 104.493248,
      "hhh": "1350"
    },
    {
      "station_id": 47707000,
      "syn_id": 44290,
      "aimag": "Төв",
      "sum": "Зуунмод",
      "station_name": "Зуунмод",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1965 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01477070101Зуунмод",
      "presona_code": "4770701",
      "lat": 47.7075,
      "lon": 106.934975,
      "hhh": "1529"
    },
    {
      "station_id": 47307500,
      "syn_id": 44294,
      "aimag": "Төв",
      "sum": "Баян",
      "station_name": "Мааньт",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "7/1/1956 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01473075101Мааньт",
      "presona_code": "4730751",
      "lat": 47.2565575,
      "lon": 107.53833,
      "hhh": "1425"
    },
    {
      "station_id": 47905300,
      "syn_id": 44297,
      "aimag": "Төв",
      "sum": "Лүн",
      "station_name": "Лүн",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1994 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01479053101Лүн",
      "presona_code": "4790531",
      "lat": 47.86661,
      "lon": 105.251167,
      "hhh": "1003"
    },
    {
      "station_id": 46905100,
      "syn_id": 44340,
      "aimag": "Төв",
      "sum": "Бүрэн",
      "station_name": "Бүрэн",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "11/1/1991 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01469051101БYРЭН",
      "presona_code": "4690511",
      "lat": 46.917305,
      "lon": 105.0473,
      "hhh": "1269"
    },
    {
      "station_id": 46805900,
      "syn_id": 44344,
      "aimag": "Төв",
      "sum": "Баян-Өнжүүл",
      "station_name": "Баян-Өнжүүл",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1995 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01468059101Баян-Өнжүүл",
      "presona_code": "4680591",
      "lat": 47.04039,
      "lon": 105.954308,
      "hhh": "1206"
    },
    {
      "station_id": 47606300,
      "syn_id": 44370,
      "aimag": "Төв",
      "sum": "Алтанбулаг",
      "station_name": "Алтанбулаг",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "6/1/1997 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01476063101Алтанбулаг",
      "presona_code": "4760631",
      "lat": 47.695343,
      "lon": 106.409737,
      "hhh": "1260"
    },
    {
      "station_id": 49394800,
      "syn_id": 44191,
      "aimag": "Увс",
      "sum": "Өндөрхангай",
      "station_name": "Өндөрхангай",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "8/1/2009 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01493948001ӨНДӨРХАНГАЙ",
      "presona_code": "4939480",
      "lat": 49.2708321,
      "lon": 94.8611145,
      "hhh": null
    },
    {
      "station_id": 49492600,
      "syn_id": 44199,
      "aimag": "Увс",
      "sum": "Наранбулаг",
      "station_name": "Наранбулаг",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1974 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": "2018 оны 7 сараас станц болсон",
      "persona_register": "01494926001НАРАНБУЛАГ",
      "presona_code": "4949260",
      "lat": 49.3831139,
      "lon": 92.5856247,
      "hhh": null
    },
    {
      "station_id": 49793300,
      "syn_id": 44201,
      "aimag": "Увс",
      "sum": "Малчин",
      "station_name": "Малчин",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/2000 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01497933001МАЛЧИН",
      "presona_code": "4979330",
      "lat": 49.72889,
      "lon": 93.26861,
      "hhh": "1471"
    },
    {
      "station_id": 49390900,
      "syn_id": 44204,
      "aimag": "Увс",
      "sum": "Ховд",
      "station_name": "Хөх толгой",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "7/1/2018 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": "2018 оны 7 сарын 1нээс Ховд харуулын нэрийг өөрчилж станц болгосон",
      "persona_register": "02492910001????",
      "presona_code": "4939090",
      "lat": 49.28073,
      "lon": 90.91056,
      "hhh": "1421"
    },
    {
      "station_id": 50593600,
      "syn_id": 44211,
      "aimag": "Увс",
      "sum": "Тэс",
      "station_name": "Тэс",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1992 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01505936001ТЭС",
      "presona_code": "5059360",
      "lat": 50.476387,
      "lon": 93.60083,
      "hhh": "798"
    },
    {
      "station_id": 49892100,
      "syn_id": 44212,
      "aimag": "Увс",
      "sum": "Улаангом",
      "station_name": "Улаангом",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1943 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01498921001УЛААНГОМ",
      "presona_code": "4989210",
      "lat": 49.97162,
      "lon": 92.07796,
      "hhh": "939"
    },
    {
      "station_id": 49694400,
      "syn_id": 44213,
      "aimag": "Увс",
      "sum": "Баруунтуруун",
      "station_name": "Баруунтуруун",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "7/1/1960 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01496944001БАРУУНТУРУУН",
      "presona_code": "4969440",
      "lat": 49.65864,
      "lon": 94.40406,
      "hhh": "1232"
    },
    {
      "station_id": 49191700,
      "syn_id": 44215,
      "aimag": "Увс",
      "sum": "Өмнөговь",
      "station_name": "Хар-Ус",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1963 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01491917001ХАР-УС",
      "presona_code": "4919170",
      "lat": 49.10499,
      "lon": 91.72619,
      "hhh": "1050"
    },
    {
      "station_id": 48893100,
      "syn_id": 44216,
      "aimag": "Увс",
      "sum": "Завхан",
      "station_name": "Завхан",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1975 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01488931001ЗАВХАН",
      "presona_code": "4889310",
      "lat": 48.8216667,
      "lon": 93.10306,
      "hhh": "1050"
    },
    {
      "station_id": 47906800,
      "syn_id": 44226,
      "aimag": "Улаанбаатар",
      "sum": "Улаанбаатар",
      "station_name": "Хан-Уул",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": null,
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": null,
      "presona_code": "4790681",
      "lat": 47.89417,
      "lon": 106.8825,
      "hhh": "1282"
    },
    {
      "station_id": 48007500,
      "syn_id": 44248,
      "aimag": "Улаанбаатар",
      "sum": "Улаанбаатар",
      "station_name": "Тэрэлж",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1986 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01480075101ТЭРЭЛЖ",
      "presona_code": "4800751",
      "lat": 47.9886665,
      "lon": 107.456566,
      "hhh": "1540"
    },
    {
      "station_id": 47806800,
      "syn_id": 44291,
      "aimag": "Улаанбаатар",
      "sum": "Улаанбаатар",
      "station_name": "Буянт-Ухаа",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "4/1/1936 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01478068101БУЯНТ-УХАА",
      "presona_code": "4780681",
      "lat": 47.8494377,
      "lon": 106.754784,
      "hhh": "1286"
    },
    {
      "station_id": 47907000,
      "syn_id": 44292,
      "aimag": "Улаанбаатар",
      "sum": "Улаанбаатар",
      "station_name": "Улаанбаатар",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "4/1/1964 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01479070101УЛААНБААТАР",
      "presona_code": "4790701",
      "lat": 47.9184456,
      "lon": 106.848343,
      "hhh": "1306.4"
    },
    {
      "station_id": 47808400,
      "syn_id": 44296,
      "aimag": "Улаанбаатар",
      "sum": "Улаанбаатар",
      "station_name": "Багануур",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "9/1/1976 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01478084101БАГАНУУР",
      "presona_code": "4780841",
      "lat": 47.7828178,
      "lon": 108.3624,
      "hhh": "1350"
    },
    {
      "station_id": 48091600,
      "syn_id": 44218,
      "aimag": "Ховд",
      "sum": "Ховд",
      "station_name": "Ховд",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1937 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01480916001Ховд",
      "presona_code": "4809160",
      "lat": 47.9958,
      "lon": 91.6327,
      "hhh": "1405"
    },
    {
      "station_id": 46792300,
      "syn_id": 44260,
      "aimag": "Ховд",
      "sum": "Мөнххайрхан",
      "station_name": "Мөнххайрхан",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "11/1/1989 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01467923001Мөнххайрхан",
      "presona_code": "4679230",
      "lat": 48.3325,
      "lon": 92.63416,
      "hhh": "1940"
    },
    {
      "station_id": 47192800,
      "syn_id": 44264,
      "aimag": "Ховд",
      "sum": "Зэрэг",
      "station_name": "Зэрэг",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "10/1/1973 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01471928001Зэрэг",
      "presona_code": "4719280",
      "lat": 47.1105537,
      "lon": 92.8444443,
      "hhh": "1152"
    },
    {
      "station_id": 46191600,
      "syn_id": 44265,
      "aimag": "Ховд",
      "sum": "Булган",
      "station_name": "Байтаг",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1963 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01461916001Байтаг",
      "presona_code": "4619160",
      "lat": 46.0947,
      "lon": 91.5526,
      "hhh": "1186"
    },
    {
      "station_id": 46792800,
      "syn_id": 44267,
      "aimag": "Ховд",
      "sum": "Мөст",
      "station_name": "Мөст",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/2000 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01467928001Мөст",
      "presona_code": "4679280",
      "lat": 46.6941681,
      "lon": 92.79472,
      "hhh": "2040"
    },
    {
      "station_id": 48693000,
      "syn_id": 44269,
      "aimag": "Ховд",
      "sum": "Чандмань",
      "station_name": "Чандмань",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "4/1/1992 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01486930001Чандмань",
      "presona_code": "4869300",
      "lat": 47.6642,
      "lon": 92.8125,
      "hhh": "1642"
    },
    {
      "station_id": 46693300,
      "syn_id": 44271,
      "aimag": "Ховд",
      "sum": "Цэцэг",
      "station_name": "Цэцэг",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "12/1/2007 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01466933001Цэцэг",
      "presona_code": "4669330",
      "lat": 46.5908,
      "lon": 93.2722,
      "hhh": "1720"
    },
    {
      "station_id": 47492200,
      "syn_id": 44361,
      "aimag": "Ховд",
      "sum": "Манхан",
      "station_name": "Манхан",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/2001 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01474922001Манхан",
      "presona_code": "4749220",
      "lat": 47.4196,
      "lon": 92.2241,
      "hhh": "1630"
    },
    {
      "station_id": 48191500,
      "syn_id": 44364,
      "aimag": "Ховд",
      "sum": "Ховд",
      "station_name": "Дунд-Ус",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "11/1/2006 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01481915001ДУНД-УС",
      "presona_code": "4819150",
      "lat": 48.12634,
      "lon": 91.3733749,
      "hhh": "1710"
    },
    {
      "station_id": 50101600,
      "syn_id": 44202,
      "aimag": "Хөвсгөл",
      "sum": "Эрдэнэбулган",
      "station_name": "Эрдэнэбулган",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/2013 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": null,
      "presona_code": null,
      "lat": 50.120224,
      "lon": 101.583069,
      "hhh": "1150"
    },
    {
      "station_id": 51199700,
      "syn_id": 44203,
      "aimag": "Хөвсгөл",
      "sum": "Ренчинлхүмбэ",
      "station_name": "Ренчинлхүмбэ",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "10/1/1973 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01511997001РЕНЧИНЛХYМБЭ",
      "presona_code": "5119970",
      "lat": 51.11395,
      "lon": 99.6685638,
      "hhh": "1583.9"
    },
    {
      "station_id": 50299000,
      "syn_id": 44206,
      "aimag": "Хөвсгөл",
      "sum": "Баянзүрх",
      "station_name": "Баянзүрх",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "11/1/2000 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "02502990001БАЯНЗYРХ",
      "presona_code": "5029900",
      "lat": 50.1790123,
      "lon": 98.97562,
      "hhh": "1630"
    },
    {
      "station_id": 50400200,
      "syn_id": 44207,
      "aimag": "Хөвсгөл",
      "sum": "Хатгал",
      "station_name": "Хатгал",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "2/1/1964 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01504002101ХАТГАЛ",
      "presona_code": "5040021",
      "lat": 50.4393463,
      "lon": 100.150658,
      "hhh": "1668.4"
    },
    {
      "station_id": 50500500,
      "syn_id": 44208,
      "aimag": "Хөвсгөл",
      "sum": "Цагаан-Үүр",
      "station_name": "Цагаан-Үүр",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "12/1/1991 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01505005101ЦАГААН-YYР",
      "presona_code": "5050051",
      "lat": 50.5523338,
      "lon": 101.514427,
      "hhh": "1100"
    },
    {
      "station_id": 49697800,
      "syn_id": 44222,
      "aimag": "Хөвсгөл",
      "sum": "Цэцэрлэг",
      "station_name": "Чандагат",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1984 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01496978001ЧАНДАГАТ",
      "presona_code": "4969780",
      "lat": 49.5222931,
      "lon": 97.73516,
      "hhh": "1883.1"
    },
    {
      "station_id": 49101400,
      "syn_id": 44223,
      "aimag": "Хөвсгөл",
      "sum": "Рашаант",
      "station_name": "Рашаант",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "8/1/2002 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "02491014101РАШААНТ",
      "presona_code": "4910141",
      "lat": 49.1211662,
      "lon": 101.456085,
      "hhh": "1377.6"
    },
    {
      "station_id": 49602000,
      "syn_id": 44230,
      "aimag": "Хөвсгөл",
      "sum": "Тариалан",
      "station_name": "Тариалан",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1963 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01496020101ТАРИАЛАН",
      "presona_code": "4960201",
      "lat": 49.6088676,
      "lon": 101.985054,
      "hhh": "1236"
    },
    {
      "station_id": 49600200,
      "syn_id": 44231,
      "aimag": "Хөвсгөл",
      "sum": "Мөрөн",
      "station_name": "Мөрөн",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1941 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01496002101МӨРӨН",
      "presona_code": "4960021",
      "lat": 49.6388054,
      "lon": 100.166946,
      "hhh": "1288.4"
    },
    {
      "station_id": 49099600,
      "syn_id": 44234,
      "aimag": "Хөвсгөл",
      "sum": "Шинэ-Идэр",
      "station_name": "Шинэ-Идэр",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "11/1/1999 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01490996001ШИНЭ-ИДЭР",
      "presona_code": "4909960",
      "lat": 48.950222,
      "lon": 99.5348053,
      "hhh": "1610"
    },
    {
      "station_id": 48699400,
      "syn_id": 44238,
      "aimag": "Хөвсгөл",
      "sum": "Жаргалант",
      "station_name": "Жаргалан",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "9/1/2007 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "02486994001ЖАРГАЛАН",
      "presona_code": "4869940",
      "lat": 48.58072,
      "lon": 99.34784,
      "hhh": "1560"
    },
    {
      "station_id": 49011600,
      "syn_id": 44254,
      "aimag": "Хэнтий",
      "sum": "Дадал",
      "station_name": "Дадал",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "3/1/1961 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01490116101ДАДАЛ",
      "presona_code": "4901161",
      "lat": 49.021946,
      "lon": 111.625,
      "hhh": "994"
    },
    {
      "station_id": 48610600,
      "syn_id": 44257,
      "aimag": "Хэнтий",
      "sum": "Биндэр",
      "station_name": "Биндэр",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1941 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01486106101БИНДЭР",
      "presona_code": "4861061",
      "lat": 48.61972,
      "lon": 110.606941,
      "hhh": "1049"
    },
    {
      "station_id": 46609400,
      "syn_id": 44299,
      "aimag": "Хэнтий",
      "sum": "Дархан",
      "station_name": "Оргил",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "9/1/1983 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01466094101ОРГИЛ",
      "presona_code": "4660941",
      "lat": 46.6318321,
      "lon": 109.409386,
      "hhh": "1200"
    },
    {
      "station_id": 47308700,
      "syn_id": 44301,
      "aimag": "Хэнтий",
      "sum": "Хэрлэнбаян-Улаан",
      "station_name": "Хэрлэнбаян-Улаан",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "8/1/2009 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01473087101ХЭРЛЭНБАЯН-УЛААН",
      "presona_code": "4730871",
      "lat": 47.2135277,
      "lon": 108.7378,
      "hhh": null
    },
    {
      "station_id": 47812100,
      "syn_id": 44302,
      "aimag": "Хэнтий",
      "sum": "Баян-Овоо",
      "station_name": "Баян-Овоо",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1962 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01478121101БАЯН-ОВОО",
      "presona_code": "4781211",
      "lat": 47.7858772,
      "lon": 112.1108,
      "hhh": "925.01"
    },
    {
      "station_id": 47909800,
      "syn_id": 44303,
      "aimag": "Хэнтий",
      "sum": "Өмнөдэлгэр",
      "station_name": "Өмнөдэлгэр",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1972 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": "2013 оны 8 сараас станц болсон",
      "persona_register": "02479098101ӨМНӨДЭЛГЭР",
      "presona_code": "4790981",
      "lat": 47.8866653,
      "lon": 109.803055,
      "hhh": null
    },
    {
      "station_id": 47310700,
      "syn_id": 44304,
      "aimag": "Хэнтий",
      "sum": "Өндөрхаан",
      "station_name": "Өндөрхаан",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "8/1/1936 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01473107101ӨНДӨРХААН",
      "presona_code": "4731071",
      "lat": 47.3082275,
      "lon": 110.624527,
      "hhh": "1032.8"
    },
    {
      "station_id": 46210800,
      "syn_id": 44307,
      "aimag": "Хэнтий",
      "sum": "Галшар",
      "station_name": "Галшар",
      "type_string": "станц",
      "type_num": 1,
      "start_obs_date": "1/1/1996 0:00",
      "status_string": "ажиллаж байгаа",
      "status": 1,
      "comment": null,
      "persona_register": "01462108101ГАЛШАР",
      "presona_code": "4621081",
      "lat": 46.24289,
      "lon": 110.8401,
      "hhh": "1200"
    }
   ]

const Map=(props)=>{

    const [center, setCenter] = useState({ lat: 47.084622, lng: 104.248357 });
    const [modal, setModal] = useState(false);
    const [singleData, setSingleData] = useState({});
    
    const openModal=(e)=>{
        setSingleData(e)
        setModal(true)
    }
    const option =[]
    data.map(el=>{
        option.push({
                value: el.station_id,
                label: `${el.aimag},${el.sum}`
        })
    })

    const onChangeHandler=(e)=>{
        const dd = data.filter(el=>el.station_id==e)
        if(dd.length>0){
            setSingleData(dd[0])
        }
    }
    useEffect(()=>{
      setModal(props.visible)
    },[props.visible])
    
    const onChange=(e)=>{
      setModal(e)
      props.onChange(e);
    }

    var geojsonMarkerOptions = {
      radius: 5,
      fillColor: "#0096FF",
      color: "#000",
      weight: 1,
      opacity: 1,
      fillOpacity: 1 
  };

    return(
        <div className='leaft-header relative truncate '>
        <MapContainer className='z-0' center={center} zoom={5} >
            <TileLayer
            // minZoom={4}
            // maxZoom={8}
            // attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            attribution=''
            url='https://tile.openstreetmap.org/{z}/{x}/{y}.png' //"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png 'https://api.mapbox.com/v3/mapbox.satellite.json'"
            
            />
             <GeoJSON style={{
              color:'black',
              fillColor:"#fff",
              opacity:1,
              weight:1,
              fillOpacity:0.1,
             }} data={Mongolian_map}/>

            {
                (data || []).map(el=>
                    parseInt(el.lat)>0 &&  parseInt(el.lon)>0 ?
                        <CircleMarker
                            key={el.syn_id}
                            center={[el.lat, el.lon]}
                            pathOptions={geojsonMarkerOptions}
                            radius={5}
                            // fillOpacity={active === initiative.id ? 1 : 0.5}
                            eventHandlers={{
                                click: () => {
                                    openModal(el)
                                },
                              }}
                      ></CircleMarker>
                    : null
                )
            }
        </MapContainer>
        <Modal width={500} onCancel={()=>onChange(false)}  onOk={()=>onChange(false)}  open={modal} >
            <Space className='p-2' direction="vertical"  size="small">

                <Select
                    // mode="multiple"
                    // allowClear
                    className='w-full text-sm outline-none rounded-md focus:ring-2 m-0 p-0 cursor-pointer'
                    placeholder="Сумын нэр сонгох"
                    options={option}
                    onChange={(e)=>onChangeHandler(e)}
                />
                <Space direction="horizontal"  size="small">
                    Аймаг:<Tag>{singleData.aimag}</Tag>
                </Space>
                <Space direction="horizontal"  size="small">
                    Сум:<Tag>{singleData.sum}</Tag>
                </Space>
                <Space direction="horizontal"  size="small">
                    Станцын нэр:<Tag>{singleData.station_name}</Tag>
                </Space>
                <Space direction="horizontal"  size="small">
                    Ажиглалт хийж эхлсэн хугацаа:<Tag>{dayjs(singleData.start_obs_date).format("YYYY-MM-DD")}</Tag>
                </Space>
                <Space direction="horizontal"  size="small">
                    Мэдээлэл:<Tag>{singleData.comment}</Tag>
                </Space>
                <Space direction="horizontal"  size="small">
                    Өргөрөг:<Tag>{singleData.lat}</Tag>
                </Space>
                <Space direction="horizontal"  size="small">
                    Уртраг<Tag>{singleData.lon}</Tag>
                </Space>
            </Space>

        </Modal>
        <Row className='absolute top-0 left-0 z-10 ml-12 mt-2'>
            <Col><Tag className='text-xs lg:text-lg' color='blue'>Цаг уурын ажиглалтын станцын байршил.</Tag></Col>
            <Col><Tag className='text-xs lg:text-lg' color='red'>Тухайн станцын мэдээллийг авахдаа газрын зураг дээрх цэг дээр дарж авна уу.</Tag></Col>
         </Row>
        </div>
    )

}

export default Map