import * as actionTypes from '../actionTypes';
const initialState = {
    success:false,
    loading:false,
    aimags:[],
    requests:[],
};
export default  (state = initialState,action)=>{
    switch (action.type) {
        case actionTypes.getAimag.REQUEST:
            return {
                ...state,
                loading: true,
                aimags: [],
            }
        case actionTypes.getAimag.RESPONSE:
            return {
                ...state,
                loading: false,
                aimags:action.payload.aimags,
                success:action.payload.success
            }
        case actionTypes.getRequest.REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
            }
        case actionTypes.getRequest.RESPONSE:
            let allReq = state.requests
            allReq.push(action.payload.request[0])
            return {
                ...state,
                loading: false,
                success:action.payload.success,
                requests:allReq
            }
        case actionTypes.getRequests.REQUEST:
            return {
                ...state,
                loading: true,
                requests:[],
                success: false,
            }
        case actionTypes.getRequests.RESPONSE:
            return {
                ...state,
                loading: false,
                requests:action.payload.requests,
                success:action.payload.success
            }
        case actionTypes.sendToMail.REQUEST:
            return {
                ...state,
                sending: true,
                success: false,
            }
        case actionTypes.sendToMail.RESPONSE:
            return {
                ...state,
                sending: false,
                success:action.payload.success
            }
        case actionTypes.getSettings.REQUEST:
            return {
                ...state,
                settings: [],
                success: false,
            }
        case actionTypes.getSettings.RESPONSE:
            return {
                ...state,
                settings: action.payload.settings,
                success:action.payload.success
            }
        case actionTypes.updateSettings.REQUEST:
            return {
                ...state,
                success: false,
            }
        case actionTypes.updateSettings.RESPONSE:
            let singleSettings = action.payload.updateSettings[0]
            // allsett.push(action.payload.updateSettings[0])
            return {
                ...state,
                settings: state.settings.map(el=>{
                    if(el.type===singleSettings.type){
                        el.value= singleSettings.value
                        el.created= singleSettings.created
                    }
                    return el
                }),
                success:action.payload.success
            }
        case actionTypes.getDashboardSingle.REQUEST:
            return {
                ...state,
                dashboard_single: [],
                success: false,
            }
        case actionTypes.getDashboardSingle.RESPONSE:
            return {
                ...state,
                dashboard_single: action.payload.dashboard_single[0],
                success: true,
            }
        case actionTypes.getDashboardMulti.REQUEST:
            return {
                ...state,
                dashboard_multi: [],
                success: false,
            }
        case actionTypes.getDashboardMulti.RESPONSE:
            return {
                ...state,
                dashboard_multi: action.payload.dashboard_multi,
                success: true,
            }
        default:
            return state;
    }
}