import { FaListAlt } from 'react-icons/fa';
import Side from "./SideMenu";
import User from './User';
import Card from './Card';
import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { getLogin } from '../redux/actions/userAction';
import { useNavigate } from 'react-router-dom';

const reducer = ({ user }) => ({  user });
const Admin=(props)=>{
    const { user } = props
    const navigate = useNavigate();
    useEffect(()=>{
        props.dispatch(getLogin()).then(res=>{
            if(res?.auth?.status==="admin"){
              navigate(`/admin/${props.selected}`)
            } else if(res?.auth?.status==="user") {
              navigate("/request")
            } else if(res?.auth==={} || !res?.loggedIn) {
                navigate("/login")
            }
         })
    },[])
    

    return(
        // <div className='grid grid-rows-6 grid-cols-6 gap-4 justify-between'>
        //     <div className="xl:row-span-6 col-span-1"><Side selected={props.selected}/></div>
        //     <div className="row-span-1 relative col-span-5 flex justify-end items-center"><User user={user}/></div>
        //     <div className="row-span-5 col-span-6 xl:col-span-5 flex justify-start p-4"><Card selected={props.selected}/></div>
        // </div>
        <div className='grid row-auto xl:grid-rows-6 grid-cols-6 gap-4'>
            <div className="xl:row-span-6 col-span-1"><Side selected={props.selected}/></div>
            <div className="xl:row-span-1 col-span-5 flex justify-end"><User user={user}/></div>
            <div className="row-span-6 col-span-6 xl:row-span-5  xl:col-span-5 flex justify-start p-4"><Card selected={props.selected}/></div>
        </div>
    )
}

export default connect(reducer)(Admin);