import * as actionTypes from '../actionTypes';
const initialState = {
    createInvoice: {},
    payLoading:false,
    success:false,
    checkPayLoading:false,
    checkPayment:{}
};
export default  (state = initialState,action)=>{
    switch (action.type) {
        case actionTypes.createInvoice.REQUEST:
            return {
                ...state,
                payLoading: true,
                createInvoice: {},
            }
        case actionTypes.createInvoice.RESPONSE:
            return {
                ...state,
                payLoading: false,
                createInvoice:action.payload.createInvoice,
            }
        case actionTypes.checkPayment.REQUEST:
            return {
                ...state,
                checkPayLoading: true,
                checkPayment: {},
                req: {},
            }
        case actionTypes.checkPayment.RESPONSE:
            return {
                ...state,
                checkPayLoading: false,
                checkPayment:action.payload.checkPayment,
                req:action.payload.request,
            }
        case actionTypes.getInvoice.REQUEST:
            return {
                ...state,
                payLoading: true,
                getInvoice: {},
            }
        case actionTypes.getInvoice.RESPONSE:
            return {
                ...state,
                payLoading: false,
                getInvoice:action.payload.getInvoice,
            }
        case actionTypes.getPaymentList.REQUEST:
            return {
                ...state,
                loading: true,
                paymentlist: {},
            }
        case actionTypes.getPaymentList.RESPONSE:
            return {
                ...state,
                loading: false,
                paymentlist:action.payload.paymentList,
            }
        default:
            return state;
    }
}