import {applyMiddleware, compose, createStore} from 'redux';
import rootReducer from "./reducers/index"
import thunk from 'redux-thunk';
import promiseMiddleware from './promiseMiddleware';

// const store = createStore(reducers,{});
// export default store;

const finalCreateStore = compose(
    applyMiddleware(thunk, promiseMiddleware),
)(createStore);

export default function configureStore(initialState) {
    return finalCreateStore(rootReducer, initialState);
}
