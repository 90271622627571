import Logo from "../../icon/logo.png"
import {  message, Form, Input, Spin } from "antd"
import { UserOutlined  } from "@ant-design/icons"

import { forgotPassword } from '../../redux/actions/userAction';
import { connect } from "react-redux";
const reducer = ({ user }) => ({  user });

const Reset=(props)=>{
 const { user:{ loading } } = props
  
  const onFinish = (value) => {
      props.dispatch(forgotPassword(value)).then(res=>{
        if(res?.sendStatus){
            message.success('Таны емайл рүү нууц үг солих линк амжилттай дамжууллаа.', 8)
         } 
        if(res?.sendStatus === false){
            message.warning('Хэрэглэгч бүртгэлгүй байна.', 5)
        }
     })

  };

  return(
    <Spin spinning={loading || false }>
    <div className='w-full h-auto sm:h-screen flex justify-center items-center text-sm'>
      <div className="max-w-xl h-screen sm:h-auto bg-white p-8 sm:rounded-2xl border border-slate-300 drop-shadow-md flex flex-col justify-start items-start w-full">
        <div className="flex text-2xl font-bold mb-8">
          <a
            href="/">
            <img
              className="w-32"
              src={Logo}
            />
          </a>
        </div>
        <Form
          name="normal_login"
          className="login-form w-full"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
        <Form.Item
          name="email"
          type="email"
          rules={[
            {
              required: true,
              message: '* Email хаяг оруулна уу. ',
            },
            {
                type: 'email',
                message: 'Имайл хаяг оруулна уу.',
              },
          ]}
        >
          <Input className="py-2" prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
        </Form.Item>
        <Form.Item className="w-full">
            <button  onClick={onFinish} htmltype="submit" className="w-full bg-blue-500 text-white border border-blue-500 hover:bg-blue-400 py-2 rounded-md duration-300">
              Нэвтрэх
            </button>
        </Form.Item>
        </Form>
      </div>
    </div>
    </Spin>
  )
    

}
export default connect(reducer)(Reset)