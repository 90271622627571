import React, { useEffect, useState } from 'react';
import { Dropdown, Space, Avatar, Button, Spin, Badge, Modal, Form, Input, message } from "antd"
import {  UserOutlined  } from "@ant-design/icons"
import { getLogin, LogOut, updateUser } from '../../redux/actions/userAction';
import { connect } from "react-redux";
import { useNavigate } from 'react-router-dom';

const reducer = ({ user }) => ({  user });

const User=(props)=>{
    const [form] = Form.useForm();
    const { user } = props
    const [usr, setUsr]= useState({})
    const [modal, setModal]= useState(false)
    const navigate = useNavigate();
    
    useEffect(()=>{
      const fetchData = async () => {
          const res = await props.dispatch(getLogin())
          if(res.loggedIn){
            setUsr(res)
            form.setFieldsValue({
                email: res.auth.email,
                firstname: res.auth.firstname,
                lastname: res.auth.lastname,
                phone: res.auth.phone,
            });
          }
      };
      fetchData();

        // props.dispatch(getLogin()).then(c=>{
        //     if(!c.loggedIn){
        //       navigate("/login")
        //     }
        //     if(c.loggedIn){
        //       setUsr(c)
        //       form.setFieldsValue({
        //           email: c.auth.email,
        //           firstname: c.auth.firstname,
        //           lastname: c.auth.lastname,
        //           phone: c.auth.phone,
        //        });
        //     }
        // })
    },[])

    const lout=()=>{
        props.dispatch(LogOut()).then(c=>{
           if(!c.loggedIn){
             navigate("/login")
           }
        })
    }
    const onFinish = (values) => {
        if(values.email){
          const user =props.dispatch(updateUser({values,id:usr?.auth.id})).then(c=>{
            if( c.success ){
                message.info(c.message , 5);
                setModal(false)
            }
          })
        }
      };
    const edit=()=>{
        setModal(true)
    }
    const items = [
        {
            key: '1',
            label: <p onClick={()=>edit()}>Мэдээлэл засах</p>,
          },
        {
          key: '2',
          danger: true,
          label: <p onClick={()=>lout()}>Гарах</p>,
        },
    ];
    return(
        <>
        <div className="flex items-start first:pt-0 last:pb-0 text-sm m-4 cursor-pointer">
            <Dropdown
                menu={{
                items,
            }}
            >
                <a className='h-auto' onClick={(e) => e.preventDefault()}>
                    <Space>
                        <Avatar icon={<UserOutlined />} />
                        <div className="overflow-hidden hidden sm:flex flex-col">
                            <p className="text-sm font-medium text-slate-900">{
                                `${usr.auth?.lastname?.split('')[0]}. ${usr.auth?.firstname}`
                            }</p>
                            <p className="text-sm text-slate-500 truncate">{
                                usr.auth?.email
                            }</p>
                        </div>
                    </Space>
                </a>
            </Dropdown>
        </div>
        <Modal cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }} open={modal} onCancel={()=>setModal(false)} onOk={()=>setModal(false)}>
        <Form  form = {form}
          name="normal_login"
          className="login-form w-full pt-10"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
        <Form.Item
          name="email"
          className="pb-1"
          rules={[
            {
              required: true,
              message: '* Email оруулна уу. ',
            },
            {
              type: 'email',
              message: 'Имайл хаяг оруулна уу.',
            },
          ]}
        >
          <Input type="email" className="py-2" placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="lastname"
          className="pb-1"
          rules={[
            {
              required: true,
              message: '* Овог нэр оруулна уу. ',
            },
          ]}
        >
          <Input  className="py-2" placeholder="Овог нэр" />
        </Form.Item>
        <Form.Item
          name="firstname"
          className="pb-1"
          rules={[
            {
              required: true,
              message: '* Өөрийн нэр оруулна уу. ',
            },
          ]}
        >
          <Input  className="py-2" placeholder="Өөрийн нэр" />
        </Form.Item>
        <Form.Item
          name="phone"
          className="pb-1"
          rules={[
            {
              required: true,
              message: '* Утасны дугаар оруулна уу. ',
            },
          ]}
        >
          <Input type="number" className="py-2" placeholder="Утасны дугаар" />
        </Form.Item>

        <Form.Item
          name="passwordOld"
          className="pb-1"
          hasFeedback
          rules={[
            {
              required: true,
              message: '* Хуучин нууц үг оруулна уу.',
            },
          ]}
        >
          <Input.Password
            className="py-2"
            // prefix={<LockOutlined className="site-form-item-icon" />}
            type="passwordOld"
            placeholder="Хуучин нууц үг"
          />
        </Form.Item>

        <Form.Item
          name="password"
          className="pb-1"
          hasFeedback
          rules={[
            {
              required: true,
              message: '* Нууц үг оруулна уу.',
            },
          ]}
        >
          <Input.Password
            className="py-2"
            // prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Нууц үг"
          />
        </Form.Item>

        <Form.Item
          name="passwordConfirm"
          dependencies={['password']}
          hasFeedback
          className="pb-1"
          rules={[
            {
              required: true,
              message: '* Нууц үг оруулна уу.',
            },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Нууц үг адилхан биш байна.'));
            },
          }),
          ]}
        >
          <Input.Password
            className="py-2"
            // prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Нууц үг давтах"
          />
        </Form.Item>
 

        <Form.Item>
          <div className="flex flex-col">
            <button htmlType="submit" className="focus:ring-1 ring-blue-700 outline-none bg-blue-500 text-white border border-blue-500 hover:bg-blue-400 py-2 rounded-md duration-300">
                Засах
            </button>
          </div>
        </Form.Item>
        </Form>
        </Modal>
        </>
    )
}

export default connect(reducer)(User);