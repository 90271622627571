import axios from 'axios';
// const DPath = process.env.REACT_APP_API_URL
// const DPath = "http://localhost:8090"
// const DPath = "http://119.40.97.106" //gadaad api-d api gsn ug ashiglahgui 
const DPath = "https://archive.weather.gov.mn" //gadaad api-d api gsn ug ashiglahgui 


const createUser_url=`${DPath}/api/user/add`;
export const addUser=(props)=>axios.post(createUser_url,props)

const login_url=`${DPath}/api/user/login`;
export const login=(props)=>axios.post(login_url,props, {withCredentials: "include"})

const getlogin_url=`${DPath}/api/user/getlogin`;
export const getLogin=(props)=>axios.get(getlogin_url, {withCredentials: "include"})

const userLogOut=`${DPath}/api/user/logout`;
export const getLogOut=(props)=>axios.get(userLogOut, {withCredentials: "include"})

export const getDashboardSingle=(props)=>axios.post(`${DPath}/api/obs/get/admin/dashboard_single`,props, {withCredentials: "include"})
export const getDashboardMulti=(props)=>axios.post(`${DPath}/api/obs/get/admin/dashboard_multi`,props, {withCredentials: "include"})

//**********************obs start***************************** */

export const getAimag=(props)=>axios.get(`${DPath}/api/obs/get/aimag`, {withCredentials: "include"})
export const getRequest=(props)=>axios.post(`${DPath}/api/obs/get/request`, props, {withCredentials: "include"})
export const getRequests=(props)=>axios.post(`${DPath}/api/obs/get/requests`, props, {withCredentials: "include"})

export const getSettings=(props)=>axios.post(`${DPath}/api/obs/get/settings`, props, {withCredentials: "include"})
export const updateSettings=(props)=>axios.post(`${DPath}/api/obs/get/updateSettings`, props, {withCredentials: "include"})

export const sendToMail=(props)=>axios.post(`${DPath}/api/obs/get/sendToMail`, props, {withCredentials: "include"})
export const downToMail=(props)=> axios({
   url: `${DPath}/api/obs/get/download-file`,
   method:'POST',
   responseType:'blob',
   data:{
           fileType:props.fileType,
           id:props.id,
           userId:props.userId || 0
   },
   withCredentials: "include"
})


//**********************obs end***************************** */

//**********************payment start***************************** */
export const createInvoice=(props)=>axios.post(`${DPath}/api/payment/createInvoice`,props, {withCredentials: "include"})
export const checkPayment=(props)=>axios.post(`${DPath}/api/payment/checkPayment`,props, {withCredentials: "include"})
export const getInvoice=(props)=>axios.post(`${DPath}/api/payment/getInvoice`,props, {withCredentials: "include"})
export const getPaymentList=(props)=>axios.post(`${DPath}/api/payment/list`,props, {withCredentials: "include"})
export const callback_url=()=>axios.get(`${DPath}/api/check-payment`, {withCredentials: "include"})
export const Check_callback_url=(props)=>axios.get(`${DPath}/api/check_callback_url/${props}`, {withCredentials: "include"})

//**********************payment end***************************** */



const userByID=`${DPath}/api/user/get`;
export const getUser=(props)=>axios.get(userByID)

const getusers=`${DPath}/api/user/get/users`;
export const getUsers=(props)=>axios.post(getusers,props, {withCredentials: "include"})

export const updateUser=(props)=>axios.post(`${DPath}/api/user/update`,props, {withCredentials: "include"})




const auth=`${DPath}/api/auth`;
export const getAuth=(props)=>axios.get(auth,props)



const forgotPassURL=`${DPath}/api/user/forgot-password`;
export const forgotPassword=(props)=>axios.post(forgotPassURL,props)


const checkAuthUrl=`${DPath}/api/user/checkauth`;
export const checkAuth=(props)=>axios.get(checkAuthUrl, {withCredentials: "include"})

const checkDateUrl=`${DPath}/api/obs/get/checkdate`;
export const checkDateData=(props)=>axios.post(checkDateUrl, {withCredentials: "include"})

