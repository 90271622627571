import * as actionTypes from '../actionTypes';
const initialState = {
    auth: {},
    success:false,
    loggedIn:false,
    users:[],
};
export default  (state = initialState,action)=>{
    switch (action.type) {
        case actionTypes.getUsers.REQUEST:
            return {
                ...state,
                loading: true,
                users: [],
            }
        case actionTypes.getUsers.RESPONSE:
            return {
                ...state,
                loading: false,
                users:action.payload.users,
                success:action.payload.success,
            }

        case actionTypes.addUser.REQUEST:
            return {
                ...state,
                loading: true,
                user: [],
            }
        case actionTypes.addUser.RESPONSE:
            return {
                ...state,
                loading: false,
                users:[
                    ...state.users,
                    action.payload.user[0]
                ],
            }
        case actionTypes.updateUser.REQUEST:
            return {
                ...state,
                loading: true,
            }
        case actionTypes.updateUser.RESPONSE:
            return {
                ...state,
                loading: false,
                // auth:action.payload.updateUser        
            }
        case actionTypes.login.REQUEST:
            return {
                ...state,
                loading: true,
                // loggedIn:false
            }
        case actionTypes.login.RESPONSE:
            return {
                ...state,
                loading: false,
                auth: action.payload.auth,
                loggedIn:action.payload.loggedIn,
                success:action.payload.success,
                token:action.payload.token
            }
        case actionTypes.LogOut.REQUEST:
            return {
                ...state,
                loading: true,
                // loggedIn: false
            }
        case actionTypes.LogOut.RESPONSE:
            return {
                ...state,
                loading: false,
                loggedIn: action.payload.loggedIn
            }
        case actionTypes.forgotPassword.REQUEST:
            return {
                ...state,
                loading: true,
                sendStatus: false,
            }
        case actionTypes.forgotPassword.RESPONSE:
            return {
                ...state,
                loading: false,
                sendStatus: action.payload.sendStatus
            }
        default:
            return state;
    }
}