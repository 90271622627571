import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import {Provider} from 'react-redux';
import {createBrowserHistory as createHistory} from "history";
import configureStore from './redux/store';

let history = createHistory();
let main = window.__INITIAL_STATE__;
const store = configureStore(main);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
      <BrowserRouter history={history}> 
          <App />
      </BrowserRouter>
  </Provider>
);
